.container {
  @apply flex items-center -space-x-[6px];
}

.eventAssigneeContainer {
  @apply min-w-6 w-[26px] h-[26px] flex justify-center items-center rounded-full border-[2px] border-transparent;
}

.eventAssigneeMoreContainer {
  @apply flex items-center justify-center w-[26px] h-[26px] rounded-full bg-green-pea-700 cursor-pointer border-[2px] border-transparent text-12-700-14 z-[2];
}

.eventAssigneeMore {
  @apply text-12-400-normal text-white flex justify-center items-center;
}

.tooltipWrap {
  @apply z-10 flex flex-col;
}

.tooltipText {
  @apply z-10 text-[10px] text-white;
}
