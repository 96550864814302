.groupContainer {
  @apply w-full h-full flex flex-row  items-center gap-[6px] p-4;
}

.avatar {
  @apply w-8 h-8 object-cover rounded-full;
}

.groupName {
  @apply text-16-400-normal text-green-pea-700 whitespace-nowrap text-ellipsis overflow-hidden cursor-default w-[70%];
}

.tooltipText {
  @apply text-white flex justify-center items-center fixed;
  font-size: 12px !important;
  font-weight: 400 !important;
  height: 50px !important;
}
