.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.backTag {
  @apply flex flex-row w-fit cursor-pointer mb-3 select-none;
}

.textBack {
  @apply text-14-400-16 text-teal-800;
}

.header {
  @apply flex items-center justify-between gap-5 mb-6;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex-1 overflow-hidden;
}
