.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.content {
  @apply flex flex-col gap-5 overflow-auto;
}

.table {
  @apply flex-1 flex justify-center overflow-hidden mt-4 min-h-[300px] h-full;
}

.pagination {
  @apply mt-4;
}

.carInsurance.yes {
  @apply text-green-pea-700;
}

.carInsurance.no {
  @apply text-primary-red-600;
}

.buttonStatus,
.legalDocuments,
.employeeDocuments,
.files,
.certifications {
  @apply flex items-center gap-2;
}

.dots {
  @apply flex items-center gap-[3px] cursor-pointer ml-5 w-5 h-5;
}

.dot {
  @apply w-[3px] h-[3px] rounded-full bg-primary-teal-950;
}

.approvalRequest,
.history {
  @apply flex flex-col;
}
