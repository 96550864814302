// Button
.btnGroup {
  @apply flex justify-center items-center focus:outline-none;
}

.btnImg {
  @apply h-[20px] w-[20px];
}

// Popup
.popupContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white min-w-[130px] border border-primary-gray-300/30 p-[10px] rounded-[8px] shadow-[6px_26px_54px_0_rgba(9,9,9,0.15)] z-20;
}

.popBtnGroup {
  @apply flex gap-[10px] flex-col;
}

.buttonOption {
  @apply flex gap-2 justify-start items-center w-full h-[29px] p-[6px] rounded-[6px];
}

.defaultBg {
  @apply hover:bg-gray-100;
}

.otherBg {
  @apply hover:bg-rose-50;
}

.labelText {
  @apply text-14-400-normal;
}

.defaultColor {
  @apply text-primary-teal-950;
}

.otherColor {
  @apply text-primary-red-600;
}

// No data
.optionNoData {
  @apply flex justify-center items-center w-full p-[6px] text-12-400-normal text-primary-gray-500;
}
