.scheduleComponent {
  @apply h-full flex flex-col overflow-hidden;
}

.toolbarWrapper {
  @apply flex items-center justify-between mb-5;
}

.toolbarLeft {
  @apply flex items-center gap-2;
}

.dateControl {
  @apply flex items-center gap-[9px] py-[7px] px-[6px] bg-green-pea-700 rounded-lg h-7;
}

.dateControlBtn {
  @apply h-[14px] w-[14px];
}

.dateControlBtnToday {
  @apply text-14-400-normal text-mineral-green-600 truncate border h-7 px-[9px] py-[6px] rounded-lg flex items-center justify-center border-athens-gray-100 bg-white font-aeonik;
}

.label {
  @apply text-20-400-normal text-mineral-green-600 truncate font-aeonik;
}

.toolbarRight {
  @apply flex align-middle gap-3;
}

.modeViewCalendar,
.btnGroup {
  @apply bg-aqua-haze-50 rounded-lg p-1;
}

.btnGroup {
  @apply min-w-0;
}

.btnCalendar {
  @apply text-mineral-green-600 text-14-400-normal truncate rounded-lg bg-none px-3 py-[5px] font-aeonik;
}

.btnCalendar.active {
  @apply bg-white text-green-pea-700;
}

.bodyWrap {
  @apply h-full w-full flex flex-col overflow-hidden rounded-lg bg-white;
}

// Schedule container
.scheduleWrap {
  @apply flex-1 overflow-y-auto h-full;
}
