.contentModal {
  @apply flex-1 gap-[40px] flex flex-col;
}

.icon {
  @apply flex justify-center;
}

.text {
  @apply w-full text-24-400-normal text-green-950 text-center;
}

.footerButton {
  @apply flex justify-center gap-[10px];
}
