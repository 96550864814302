.container {
  @apply flex flex-col gap-[20px];
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}

.repeatEveryUnit {
  @apply mt-[24px] h-[34px] flex items-center text-12-400-normal font-aeonik text-primary-sirocco-500;
}

.repeatEvery {
  @apply flex gap-2;
}

.inputRepeatEvery {
  @apply flex-1;
}
