.container {
  @apply fixed inset-0 bg-transparent z-50;
  @apply w-full flex items-center justify-center;
}

.modalEvent {
  @apply relative z-20 flex flex-col w-full max-h-[70vh] max-w-[660px] rounded-lg bg-white p-[30px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)];
  @apply transition duration-300 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
}
.eventPopup {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-6 bg-white overflow-hidden;
}

.headGroup {
  @apply w-full flex flex-col border-b border-b-nebula-100 gap-6;
}

.infoGroup {
  @apply flex w-full justify-between items-start gap-10;
}

.nameGroup {
  @apply flex items-center gap-4 w-[80%];
}

.firstLastName {
  @apply flex flex-col gap-3 w-[70%];
}

.name {
  @apply text-20-400-normal text-holly-950 space-x-1 line-clamp-1 text-ellipsis w-full;
}

.textInfoGroup {
  @apply flex items-center gap-[9px] w-full;
}

.textInfo {
  @apply w-full text-corduroy-500 text-16-400-normal line-clamp-1 text-ellipsis;
}

.textSchedule {
  @apply text-corduroy-500 text-14-400-normal;
}

.btnMap {
  @apply flex gap-[3px] items-center text-16-400-normal text-green-pea-700 self-start;
}

.iconMap {
  @apply w-5 h-5;
}

.tabContent {
  @apply flex h-full flex-1 flex-col overflow-y-auto;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}

.navTabGroup {
  @apply overflow-x-auto;
}

.popupContainer {
  @apply flex relative flex-col z-20 rounded-lg bg-white p-[30px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)];
  @apply transition duration-300 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
  width: 660px !important;
}
