.formContainer {
  @apply flex-1 flex flex-col overflow-hidden;
}

.formBody {
  @apply flex-1 flex flex-col gap-5 pr-2 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.radioGroup {
  @apply flex flex-row gap-3 w-full mt-[10px];
}

.textLabel {
  @apply flex flex-row gap-[2px] mb-[10px] w-full items-center text-primary-pewter-300 text-12-400-normal;
}

.formFooter {
  @apply flex items-center justify-end gap-3 mt-10;
}
