.detailClientAndProspectComponent {
  @apply flex-1 flex flex-col h-full w-full overflow-hidden gap-4;
}

.head {
  @apply flex-1 flex justify-end gap-[10px];
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.btnAction {
  @apply flex items-center gap-[10px];
}
