.radioCheckboxComponent {
  @apply w-full flex gap-[10px];
}

.radioCheckboxGroup {
  @apply flex gap-y-[10px] gap-x-5 flex-wrap;
}

.radioCheckboxWrap.borderWrap {
  @apply flex items-center gap-5;
  @apply p-3 border border-primary-geyser-100 rounded-lg;
}

.radioCheckboxContent {
  @apply flex items-center gap-[6px];
}

.checkbox {
  @apply border-primary-neutral-400 cursor-pointer size-4 border rounded-sm flex items-center justify-center;
}

.checkbox.checked {
  @apply bg-green-pea-700 border-transparent;
}

.textName {
  @apply text-primary-sirocco-500 text-12-400-normal line-clamp-1 truncate break-words whitespace-normal cursor-pointer;
}

.messageError {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.label {
  @apply inline-block text-12-400-normal text-primary-pewter-300 capitalize font-aeonik;
}

.required {
  @apply text-primary-red-600;
}

.radioWrap {
  @apply w-[16px] h-[16px] flex items-center justify-center;
}

.radioInput {
  @apply appearance-none w-[16px] h-[16px] cursor-pointer rounded-[50%] border-[1px] border-solid border-primary-neutral-400 bg-white relative;
}

.radioInput:checked {
  @apply border-green-pea-700 bg-white;
}

.radioInput:checked::before {
  @apply content-[''] block w-2 h-2 bg-green-pea-700 absolute -translate-x-2/4 -translate-y-2/4 rounded-[50%] left-2/4 top-2/4;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1 text-12-400-normal text-green-pea-700;
}
