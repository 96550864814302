.activitiesTabComponent {
  @apply h-full w-full flex overflow-hidden;
}

.bodyWrap {
  @apply flex-1 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.noData {
  @apply flex justify-center items-center h-full flex-1;
}

.containerSpinner {
  @apply h-16;
}

.containerSpinner.hideSpinner {
  @apply h-0;
}
