.statisticCardComponent {
  @apply w-full rounded-[4px] h-fit;
}

.headerStatisticCard {
  @apply bg-white px-5 pt-5 pb-4 flex justify-between gap-[10px] rounded-tl-[4px] rounded-tr-[4px] flex-wrap;
}

.headerRight {
  @apply flex flex-col gap-[6px];
}

.lineChart {
  @apply w-[112px] h-[63px];
}

.titleStatisticCard {
  @apply text-cutty-sark-500 text-16-400-normal;
}

.quantityStatisticCard {
  @apply text-holly-950 text-32-400-38;
}

.footerStatisticCard {
  @apply px-5 border-t-[1px] border-t-primary-gray-200 py-[10px] bg-desert-storm-50 rounded-bl-[4px] rounded-br-[4px] flex items-center;
}

.desc {
  @apply text-12-400-normal font-aeonik text-mantle-400 flex items-center gap-1;
}

.percent {
  @apply text-green-pea-700;
}
