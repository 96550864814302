.container {
  @apply p-5 w-full h-full flex flex-col;
}

.headerChart {
  @apply flex items-center justify-between gap-[10px];
}

.title {
  @apply text-20-400-normal text-black;
}

.donutChart {
  @apply flex justify-center mt-[53px] mb-[20px];
}

.footerChart {
  @apply flex items-center gap-[10px] justify-center mb-7;
}

.serviceForRating {
  @apply text-holly-950 text-16-250-19;
}

.noData {
  @apply flex w-full h-full justify-center items-center;
}
