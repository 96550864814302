.container {
  @apply w-full h-full bg-white p-5 rounded flex flex-col gap-6 overflow-hidden;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex-1 overflow-hidden pr-1 grid grid-cols-[240px_1fr] gap-5;
}

.tabs {
  @apply p-3 rounded-lg bg-white border border-primary-gray-200 flex flex-col gap-1 overflow-hidden;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.tabContent {
  @apply w-full h-full overflow-hidden;
}
