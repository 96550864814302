.container {
  @apply flex flex-col max-h-[100%] font-aeonik;
}

.contentModal {
  @apply overflow-y-auto flex-1 gap-5 flex flex-col pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.twoCol {
  @apply grid grid-cols-2 gap-[10px];
}

.contentWrapRadio {
  @apply grid grid-cols-3;
}

.radioGroup {
  @apply flex flex-row gap-10 w-full mt-[10px];
}

.textLabel {
  @apply flex flex-row gap-[2px] mb-[10px] w-full items-center text-primary-pewter-300 text-12-400-normal;
}

.formListGroup {
  @apply flex flex-col gap-5;
}

.containerTask {
  @apply w-full flex justify-between gap-[10px];
}

.theeCol {
  @apply flex-1 gap-x-5 grid grid-cols-[2fr_1.3fr_3.2fr];
}

.btnRemoveContainer {
  @apply mt-8 w-4 h-4;
}

.footerModal {
  @apply flex justify-end gap-3 pt-5;
}

.containerInput {
  @apply flex flex-col text-12-400-normal font-aeonik relative;
}

.inputLabel {
  @apply w-fit mb-[10px] text-primary-pewter-300;
}

.textInput {
  @apply p-[10px] w-full h-[34px] max-h-[50px] rounded-lg border-[1px] border-primary-geyser-100 flex-1 outline-none text-12-400-normal text-primary-sirocco-500;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.btnTextAdd {
  @apply text-12-400-normal text-primary-teal-800;
}

.labelRequired {
  @apply text-primary-red-600;
}

.textLabelTask {
  @apply flex flex-row pb-1 text-primary-teal-800 text-16-500-normal border-b border-primary-geyser-100;
}
