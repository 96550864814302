.container {
  @apply w-full flex flex-col gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}

.dnrWrap {
  @apply flex flex-col gap-[10px];
}

.label {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik;
}

.dnr {
  @apply flex items-center gap-10 border rounded-lg border-primary-geyser-100 p-3;
}

.radioLabel {
  @apply text-12-400-normal;
}
