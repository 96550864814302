.caregiverContainer {
  @apply w-full h-full bg-white rounded-[4px] p-5 font-aeonik overflow-hidden;
}

.requestApplicantContentWrap {
  @apply flex flex-col w-full h-full gap-y-[30px] overflow-y-auto;
}

.content {
  @apply flex flex-col flex-1 w-full h-full gap-3;
}

.headContent {
  @apply flex justify-between items-center pr-2;
}

.titleContent {
  @apply text-holly-950 text-24-400-normal;
}

.baseTable {
  @apply flex-1 overflow-y-auto;
}

.buttonBase {
  @apply flex gap-[10px];
}

.bodyWrapCaregiver {
  @apply grid gap-[21px] grid-cols-5 overflow-x-hidden;
}

.bodyWrapCareAlerts {
  @apply grid gap-[30px] grid-cols-3;
}

.bodyWrapCaregiverReferral {
  @apply flex gap-[29px] overflow-x-hidden;
}

.noData {
  @apply flex justify-center items-center h-full flex-1;
}

.bodyWrap {
  @apply min-h-[152px];
}