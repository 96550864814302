.container{
   @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] overflow-hidden;
}

.content {
   @apply h-full w-full flex flex-col overflow-hidden;
 }
 
 .tableWrap {
   @apply flex-1 flex justify-center overflow-hidden;
 }

.paginationTable {
   @apply mt-4;
 }