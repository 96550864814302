.container {
  @apply w-full h-full p-5 bg-white rounded-[4px];
}

.content {
  @apply w-full h-full py-[30px];
}

.eventContainer {
  @apply w-full h-full gap-[4px] pr-1 rounded-xl p-[6px];
  @apply relative;
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.eventContainer.monthEvent {
  @apply flex justify-center h-[60px];
}

.eventWrap {
  @apply flex flex-1 flex-row gap-2 items-center overflow-y-auto overflow-x-hidden;
}

.assignees {
  @apply flex items-center justify-center ml-1;
}

.firstLastName {
  @apply flex flex-col justify-center truncate overflow-hidden;
}

.eventName {
  @apply w-auto h-auto text-14-400-24 text-white text-ellipsis whitespace-nowrap overflow-hidden;
}

.eventTime {
  @apply truncate mt-[2px] text-8-400-10 text-white overflow-hidden;
}

.tooltipWrap {
  @apply z-10 max-w-[370px] text-12-400-normal text-white;
}
