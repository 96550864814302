.container{
    @apply w-full flex flex-col gap-5 text-14-400-normal text-primary-sirocco-500;
}

.twoCol {
    @apply grid grid-cols-2 gap-5;
}

.eliminationControl{
    @apply p-3 border border-primary-geyser-100 rounded-lg;
}

.label {
    @apply block text-12-400-normal mb-[10px];
}