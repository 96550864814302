.container {
  @apply bg-white overflow-hidden flex flex-col gap-4 min-w-[250px];
}

.avatarFirstLastName {
  @apply pb-3 flex gap-2 items-center border-b border-nebula-100;
}

.information {
  @apply flex flex-col gap-1;
}

.firstLastName {
  @apply text-16-400-normal text-green-pea-700 font-aeonik;
}

.roleName {
  @apply text-14-400-normal text-green-pea-700/80 font-aeonik;
}

.menu,
.menuItem,
.signOut {
  @apply w-full;
}

.menuItemGroup,
.signOut {
  @apply flex items-center min-h-9 rounded-md cursor-pointer p-2;
  @apply hover:bg-gray-100;
  @apply transition-all duration-300;
}

.menuItemIcon,
.signOutIcon {
  @apply mr-1 w-5 h-5 object-cover;
}

.menuItemLabel,
.signOutLabel {
  @apply flex-1 w-full text-14-400-normal text-cutty-sark-500;
}

.line {
  @apply w-full h-[1px] bg-nebula-100;
}

.signOut {
  @apply hover:bg-rose-50;
}

.signOutLabel {
  @apply text-primary-red-600;
}
