.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex justify-between flex-1 w-full h-full gap-5 overflow-hidden;
}

.filtersAndMap {
  @apply flex flex-col flex-1;
}

.filtersAndMapContainer {
  @apply flex flex-col h-full rounded-[8px] border border-gray-300 p-6 gap-5 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.mapContainer {
  @apply flex-1 w-full overflow-hidden;
}

.chartWrapper {
  @apply flex-1;
}

.chartContainer {
  @apply rounded h-full;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}
