.baseTextareaContainer {
  @apply flex flex-col text-12-400-normal font-aeonik relative;
}

.baseTextareaLabel {
  @apply w-fit mb-[10px] text-primary-pewter-300;
}

.baseTextareaContent {
  @apply bg-white flex justify-center items-center outline-0 rounded-lg border border-primary-geyser-100 w-full  py-[10px];
}

.textarea {
  @apply outline-0 text-primary-sirocco-500 text-12-400-normal w-full h-full px-[10px] rounded-lg resize-none;
}

.textarea::placeholder {
  @apply text-primary-sirocco-500 text-12-400-normal;
}

.baseTextareaError {
  @apply min-h-4 px-1 text-primary-red-600 text-12-400-normal left-0 top-full mt-[10px];
}

.required {
  @apply text-primary-red-600;
}
