.container {
  @apply fixed inset-0 bg-primary-nandor-600 bg-opacity-[78%] z-50;
}

.modal {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[340px] max-h-[80vh] p-[30px] bg-white rounded-lg flex flex-col items-center gap-10;
}

.modalBody {
  @apply overflow-y-auto w-full;
}

.title {
  @apply w-full text-24-400-normal text-green-950 text-center break-words line-clamp-5;
}

.description {
  @apply text-16-400-normal text-zinc-500 text-center mt-4 w-[260px];
}

.actions {
  @apply flex items-center justify-end gap-[10px];
}

.iconWrap {
  @apply w-[80px] h-[80px] bg-primary-teal-800/10 rounded-[80px] flex items-center justify-center;
}

.iconWrap.danger {
  @apply bg-red-100;
}

.icon {
  @apply w-10 h-10 object-cover;
}

.iconClose {
  @apply self-end cursor-pointer;
}
