.addFilesComponent {
  @apply bg-white w-full h-full rounded-lg p-[30px] overflow-y-hidden flex flex-col gap-5 min-h-[370px];
}

.headTitle {
  @apply flex w-full justify-between items-center gap-2 border-b border-nebula-100 pb-4;
}

.twoCol {
  @apply grid grid-cols-2 gap-5 mt-5;
}

.contentName {
  @apply mt-5;
}

.title {
  @apply text-16-400-normal text-holly-950;
}

.icon {
  @apply w-[19px] h-[19px] rotate-180;
}

.content {
  @apply overflow-y-scroll h-full;
}

.footerModal {
  @apply flex justify-end mt-[40px];
}
