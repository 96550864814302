.documentsTabComponent {
  @apply flex flex-col justify-between w-full h-full overflow-hidden;
}

.searchContainer {
  @apply flex justify-end mb-4;
}

.tableContainer {
  @apply flex-1 overflow-y-auto;
}

.paginationTable {
  @apply mt-4;
}

.nameDocument {
  @apply flex items-center gap-[6px];
}

.btnAction {
  @apply flex items-center gap-[10px];
}
