.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.tags {
  @apply flex gap-2 items-center flex-wrap;
}

.paginationTable {
  @apply mt-4;
}

.textTable {
  @apply text-14-400-16 text-mineral-green-600 line-clamp-2;
}

.tooltipText {
  @apply text-white absolute;
  font-size: 12px !important;
  font-weight: 400 !important;
  max-width: fit-content;
  z-index: 10;
}

.assignee {
  @apply flex gap-x-1;
}

.tableLink {
  @apply hover:underline hover:text-blue-smoke-500 cursor-pointer;
  @apply transition-all ease-in-out duration-300;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.filterItemCheckbox {
  @apply flex items-center gap-[6px];
}

.labelCheckbox {
  @apply text-14-400-16 text-mineral-green-600 cursor-pointer;
}

input[type='checkbox'] {
  @apply cursor-pointer;
}
