.container {
  @apply w-full flex items-center gap-x-[30px];
}

.tab {
  @apply text-blue-smoke-400 text-14-400-normal cursor-pointer border-b border-b-transparent min-w-[40px] text-center;
}

.activeTab {
  @apply text-green-pea-700 border-b border-b-green-pea-700;
}

.disable {
  @apply text-gray-300 cursor-not-allowed;
}

.activeTab.disable {
  @apply border-b border-b-gray-300;
}

.container.col {
  @apply w-full flex flex-col gap-y-1 max-h-full pr-1 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.container.col .tab {
  @apply flex w-full items-center min-h-9 rounded-md cursor-pointer p-2 overflow-hidden select-none;
  @apply text-16-400-normal text-cutty-sark-500;
  @apply hover:bg-gray-100;
  @apply transition-all duration-300;
}

.container.col .activeTab {
  @apply bg-green-pea-700 text-white;
  @apply hover:bg-green-pea-700;
  @apply transition-all duration-300;
}
