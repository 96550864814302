.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.content {
  @apply h-full w-full flex flex-col overflow-y-auto;
}

.btnCalendarView {
  @apply text-16-400-normal text-green-pea-700 cursor-pointer;
}