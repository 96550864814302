.container {
  height: 100%;
  min-width: 1000px;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventContainer {
  @apply h-full w-full border border-l-[4px] z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.eventContainerCollapse {
  @apply px-[10px] rounded-[4px] h-[21px] flex items-center;
}

.eventContainerExpand {
  @apply p-[12px] flex flex-col h-[95px] rounded-lg;
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

.eventName {
  @apply truncate text-12-400-normal text-green-pea-700 whitespace-normal line-clamp-1;
}

.eventNameExpand {
  @apply text-12-400-normal text-green-pea-700 break-words whitespace-normal overflow-y-auto pr-1;
  &::-webkit-scrollbar {
    @apply w-[3px];
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.eventTime {
  @apply truncate mt-[2px] text-mineral-green-600 text-10-400-12;
}

.assigneeContainer {
  @apply mt-auto;
}

.tooltipWrap {
  @apply z-10 max-w-[370px];
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white flex-col overflow-hidden flex text-[12px];
}

.noDataAvailable {
  @apply flex-1 flex h-full w-full items-center justify-center;
}
