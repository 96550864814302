.imageCircle {
  @apply text-14-400-normal rounded-full bg-alto-200 flex items-center justify-center gap-[2px] object-cover;
}

.imageCircle.more {
  @apply cursor-pointer;
}

.imageText {
  @apply text-black text-11-900-11
}
