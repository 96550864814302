.container {
  @apply w-full flex items-center justify-between gap-4;
}

.menuWrap {
  @apply flex-1 space-x-[15px];
}

.navLink {
  @apply relative text-blue-smoke-400 mr-[33px] last:mr-0 hover:text-green-pea-700 transition-all;
}

.navLinkActive {
  @apply text-green-pea-700;
}

.navLink.expanded {
  @apply mr-[15px];
}

.navLinkActive::after {
  @apply content-[""] absolute top-full mt-1 left-0 w-full h-[1px] bg-green-pea-700;
}

.logo {
  @apply block w-[95px] object-cover cursor-pointer;
}

.menuWrap {
  @apply flex-1 h-[40px] flex items-center overflow-x-auto;
}

.menuWrap::-webkit-scrollbar {
  @apply hidden;
}

.groupActionWrap {
  @apply flex items-center justify-end gap-[10px];
}

.avatar {
  @apply relative block w-[40px] h-[40px] rounded-full object-cover cursor-pointer focus:outline-none;
}

.message,
.notification {
  @apply relative w-[40px] h-[40px] p-[10px] rounded-full bg-white flex items-center justify-center transition-all hover:bg-green-pea-700/15  cursor-pointer;
}

.search {
  @apply h-[40px] bg-white flex items-center justify-between rounded-full overflow-hidden transition-all duration-500;
}

.search.collapsed {
  @apply w-auto;
}

.search.expanded {
  @apply w-[257px];
}

.searchContent {
  @apply flex-1 h-full px-[11px] text-12-400-normal text-cutty-sark-500 outline-none truncate;
}

.searchIcon {
  @apply p-[10px] flex items-center justify-center cursor-pointer hover:bg-green-pea-700/15 transition-all;
}

.popupContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white min-w-[130px] border border-primary-gray-300/30 p-4 rounded-[12px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)] z-20;
  overflow: hidden !important;
}
