.container {
  @apply bg-primary-nandor-600 bg-opacity-[78%] fixed top-0 left-0 inset-0 w-full z-40 flex items-center justify-center;
}

.modalBody {
  @apply bg-white rounded-lg p-[30px] flex flex-col items-center justify-center w-[432px] max-w-full overflow-hidden;
}

.modalIcon {
  @apply p-2 rounded-md bg-crimson-700/10 flex items-center justify-center p-3 mb-5;
}

.modalIconImg {
  @apply block w-[34px] h-[34px] max-w-[34px] object-cover;
}

.modalTitle {
  @apply text-20-600-normal text-holly-950 text-center;
}

.modalDescription {
  @apply mt-3 text-14-400-normal text-zinc-500 text-center;
}

.modalFooter {
  @apply w-full flex items-center justify-between gap-3 mt-[30px];
}
