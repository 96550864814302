// Button
.btnWrap {
  @apply flex justify-between items-center gap-2 rounded-[8px] focus:outline-none bg-primary-teal-800 px-2;
}

.btnLabel {
  @apply text-white;
}

.btnGroup {
  @apply flex gap-1;
}

// Popup
.popupContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white min-w-[180px] p-2 z-50 rounded-[8px] border-2;
}

.popHeadGroup {
  @apply flex items-center justify-between;
}

.popTitle {
  @apply text-white;
}

// Button
.popBtnGroup {
  @apply flex items-center justify-between gap-2;
}

.popContent {
  @apply flex flex-col;
}
