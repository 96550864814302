.container {
  @apply w-full h-full flex flex-col gap-4 overflow-hidden;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.buttonAddClient {
  @apply ml-auto;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.filterItemCheckbox {
  @apply flex items-center gap-[6px];
}

.labelCheckbox {
  @apply text-14-400-16 text-mineral-green-600 cursor-pointer;
}

input[type='checkbox'] {
  @apply cursor-pointer;
}
