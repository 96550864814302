.container {
  @apply w-full flex flex-col gap-5;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.favoriteFoodWrap,
.mealsItem {
  @apply flex flex-col gap-[10px];
}

.label {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik;
}

.favoriteFood {
  @apply flex flex-col gap-5;
}

.timeGroup {
  @apply grid grid-cols-4 gap-5;
}

.timeControl {
  @apply flex items-center gap-2;
}

.mealsControl {
  @apply p-3 border border-primary-geyser-100 rounded-lg;
}

.favoriteFoodWrap .label {
  @apply text-16-400-normal text-primary-pewter-300;
}
