.originStyle {
  @apply w-full flex justify-center items-center px-3 pb-[2px] gap-[6px] rounded-full bg-opacity-[10%] bg-primary-teal-800 text-14-400-normal font-aeonik;
}

.originStyle.text {
  @apply w-full flex justify-center items-center px-0 pb-0 gap-[6px] rounded-none bg-opacity-100 bg-transparent text-14-400-normal font-aeonik cursor-text;
}

.dot {
  @apply w-[6px] h-[6px] rounded-full bg-primary-teal-800;
}
