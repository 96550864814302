.basePaginationContainer {
  @apply w-full flex justify-center items-center gap-x-3;
}

.pageButton {
  @apply flex justify-center items-center w-8 h-8 rounded-[10px] bg-gray-100 text-14-400-normal text-slate-900;
}

.pageButton.disabled {
  @apply pointer-events-none cursor-default opacity-50;
}

.threeDotStyle {
  @apply text-neutral-700 text-10-700-11;
}

.active {
  @apply text-white bg-green-pea-700;
}

.leftChevronIcon {
  @apply rotate-180;
}
