.toolBar {
    @apply w-full;
}

.toolbarWrap {
   @apply  flex justify-between gap-[10px] items-center;
}

.titleToolBar {
    @apply text-holly-950 font-aeonik text-24-400-normal;
}

.childrenToolBar {
    @apply flex items-center gap-[10px];
}