.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-pewter-300 capitalize;
}

.labelRequired {
  @apply text-primary-red-600;
}

.uploadFileContainer {
  @apply w-full flex flex-col;
}

.uploadFile {
  @apply mt-2 flex items-center gap-4 flex-wrap;
}

// Img Preview
.filePreviewWrap {
  @apply flex items-center gap-2 w-fit line-clamp-1 overflow-ellipsis;
}

.imgPreview {
  @apply w-full h-full rounded-lg object-cover;
}

.iconClose {
  @apply cursor-pointer p-1 rounded-full;
}

.iconCloseImg {
  @apply size-[12px];
}

.fileName {
  @apply text-primary-sirocco-500 text-12-400-normal line-clamp-1 overflow-ellipsis max-w-[150px];
}

.browseBtn {
  @apply text-12-700-14 text-green-pea-700 underline;
}

// Btn Upload
.btnUpload {
  @apply w-full flex justify-center items-center gap-x-[6px];
  @apply rounded-lg bg-primary-teal-800/10 p-[10px] hover:opacity-60;
  @apply text-primary-teal-800 text-12-400-normal truncate;
  @apply border border-dashed border-primary-teal-800 cursor-pointer;
}

.inputUpload {
  @apply hidden;
}

// Err Message
.errMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}
