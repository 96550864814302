/* Spinner.css */
.spinnerContainer {
  @apply flex justify-center items-center my-5;
}

.spinner {
  @apply border-2 border-gray-300 border-t-green-600 rounded-full w-6 h-6 animate-spin;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
