.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.formBody {
  @apply flex-1 flex flex-col gap-5 pr-2 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.formActions {
  @apply flex items-center justify-end gap-3 mt-10;
}
