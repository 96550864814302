.container {
  @apply w-full flex flex-col gap-5;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.label {
  @apply w-fit text-primary-pewter-300;
}

.labelControl {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik;

  span {
    @apply italic;
  }
}

.activitiesControl {
  @apply flex flex-col gap-[10px];
}

.carePlanDailyRoutine {
  @apply flex flex-col gap-5;
}
