.containerBaseInput {
  @apply flex flex-col text-12-400-normal font-aeonik relative;
}

.inputBaseInput:disabled {
  @apply bg-aqua-squeeze-100;
}

.inputBaseLabel {
  @apply w-fit mb-[10px] text-primary-pewter-300;
}

.inputBaseContent {
  @apply relative;
}

.inputBaseInput {
  @apply p-[10px] text-primary-sirocco-500 text-12-400-normal rounded-lg border-[1px] border-primary-geyser-100 outline-none;
}

.inputBaseInput::placeholder {
  @apply text-primary-sirocco-500 text-12-400-normal;
}

.inputBaseIcon {
  @apply absolute top-1/2 right-[10px] -translate-y-1/2;
}

.inputBaseIconImage {
  @apply w-4 h-4 object-cover cursor-pointer;
}

.inputBaseLabelRequired {
  @apply text-primary-red-600;
}

.inputBaseMessageError {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.iconLeft {
  @apply absolute top-1/2 left-[12px] -translate-y-1/2;
}

.inputBasePassword {
  @apply px-[36px];
}
