.container {
  @apply grid grid-cols-[250px,1fr] gap-5 items-center border-b pb-3;
  @apply border-nebula-100;

  &.noBorder {
    @apply border-b-0 pb-0;
  }
}

.item {
  @apply text-14-400-normal text-wrap;
}

.item.key {
  @apply text-primary-neutral-400 truncate;

  &.alignSelfStart {
    @apply self-start;
  }
}

.item.val {
  @apply text-primary-teal-950;

  &.truncateValue {
    @apply truncate;
  }
}

.noteValue {
  @apply line-clamp-3;

  &.expanded {
    @apply line-clamp-none;
  }
}

.toggleButton {
  @apply text-14-400-normal text-primary-teal-800 mt-2 transition duration-300 ease-in-out;

  &:hover {
    @apply opacity-80;
  }
}

.tooltipWrap {
  width: 300px !important;
}

.tooltipText {
  @apply z-10 text-white text-wrap;
  font-size: 12px !important;
  font-weight: 400 !important;
}
