.conversationActions {
    @apply flex items-center gap-3;
}
  
.btnAction {
    @apply w-10 h-10 rounded-[100px] bg-aqua-haze-50 flex items-center justify-center;
}

.btnActionSearch {
    @apply relative w-10 h-10;
}

.inputSearch {
    @apply absolute w-10 h-full top-0 right-0 outline-none bg-white text-primary-teal-800 text-14-400-normal font-aeonik border border-primary-gray-200 rounded-full;
    @apply transition-all ease-linear duration-300
}

.inputSearch::placeholder {
    @apply text-primary-gray-500 font-aeonik;
}

.icon {
    @apply absolute w-10 h-10 rounded-[100px] bg-aqua-haze-50 right-0 top-0 flex items-center justify-center p-1;
}

.inputSearch.open {
    @apply w-[300px] pl-3 pr-12;
}