.container {
  @apply flex flex-col justify-between w-full h-full overflow-hidden;
}

.tableContainer {
  @apply flex-1 overflow-y-auto;
}

.paginationTable {
  @apply mt-4;
}

.btnAction {
  @apply flex items-center gap-[10px];
}
