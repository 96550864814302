.emergencyContactsSkillContainer {
  @apply p-5 rounded-[4px] border border-nebula-100 w-full flex flex-col gap-5;
}

.headTitle {
  @apply text-16-400-normal text-holly-950;
}

.bodyWrap {
  @apply flex flex-col gap-5;
}

.fiveCol {
  @apply grid grid-cols-5 gap-5;
}

.noteSection {
  @apply flex flex-row col-span-2 gap-4;
}

.noteField {
  @apply w-full;
}

.btnRemoveField {
  @apply mt-8 w-4 h-4;
}

.btnAddField {
  @apply flex items-center gap-x-1 w-fit;
  @apply transition-all duration-300 ease-in-out hover:opacity-70;
}

.btnTextAdd {
  @apply text-12-400-normal text-primary-teal-800;
}
