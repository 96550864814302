#selectMultipleTagComponent {
  .MuiOutlinedInput-notchedOutline {
    @apply border-none;
  }

  .MuiAutocomplete-inputRoot {
    @apply gap-x-[6px] max-h-[34px] overflow-y-auto;
  }

  .MuiAutocomplete-input {
    @apply h-full;
  }
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiAutocomplete-paper {
  box-shadow: none !important;
}

.Mui-focused {
  background-color: transparent !important;
}

.MuiAutocomplete-noOptions {
  text-align: center;
  padding-top: 30px !important;
  color: rgb(85 109 118) !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: calc(12px * 0.02);
  font-weight: 400;
  height: 70px;
}

@media (min-width: 600px) {
  .css-19166iz-MuiAutocomplete-listbox .MuiAutocomplete-option {
    min-height: 32px !important;
  }
}
