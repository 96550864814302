.container {
  height: 100%;
  min-width: 1000px;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventContainer {
  @apply h-full w-full rounded-r-2xl relative border border-solid z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none border border-skeptic-100;
}

.eventContainerCollapse {
  @apply px-[10px] h-4;
}

.eventContainerExpand {
  @apply p-[6px] flex flex-col h-[95px];
}

.weekEventWrap {
  @apply flex size-full overflow-y-auto flex-col gap-[4px] pr-1;
}

.expandWeekViewContainer {
  @apply flex h-full w-full flex-col p-2 rounded-md border border-skeptic-100;
}

.collapseEventContainer {
  @apply rounded-md border border-skeptic-100 p-[10px];
}

.eventCollapseDayContainer {
  @apply flex w-[372px] p-2 flex-wrap items-center gap-4 rounded-md border border-skeptic-100;
}

.eventExpandDayContainer {
  @apply flex w-[372px] flex-col p-2 rounded-r-2xl flex-wrap rounded-md border border-skeptic-100;
}

.eventContent {
  @apply flex flex-col flex-1;
}

.assigneeContainer {
  @apply mt-auto;
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

.eventName {
  @apply truncate text-12-400-normal text-holly-950 whitespace-normal line-clamp-1;
}

.eventTime {
  @apply truncate mt-[2px] text-gray-500 text-10-400-12;
}

.myScheduleWeekView {
  :global {
    .rbc-day-slot .rbc-event {
      background-color: unset;
      border: unset;
      min-height: 54px;
    }
  }
}

.myScheduleDayView {
  :global {
    .rbc-day-slot .rbc-event {
      background-color: unset;
      border: unset;
      min-height: 54px;
    }
  }
}

.tooltipWrap {
  @apply z-10 max-w-[170px];
}

.tooltip {
  @apply flex flex-col;
}

.tooltipText {
  @apply text-white flex-col overflow-hidden flex;
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}
