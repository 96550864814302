.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.head {
  @apply text-holly-950 text-24-400-normal;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.filterItemCheckbox {
  @apply flex items-center gap-[6px];
}

.labelCheckbox {
  @apply text-14-400-16 text-mineral-green-600 cursor-pointer;
}
