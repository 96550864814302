.container {
  @apply w-full flex flex-col gap-5;
}

.label {
  @apply block text-12-400-normal text-primary-pewter-300 capitalize font-aeonik mb-[10px];
}

.medicalConditionControl {
  @apply flex items-center gap-5;
  @apply p-3 border border-primary-geyser-100 rounded-lg;
}

.radioLabel {
  @apply text-12-400-normal;
}
