#scheduleTimeline {
  height: 100%;
  min-width: 1000px;

  .react-calendar-timeline .rct-dateHeader {
    border-bottom: unset;
    border-left: 1px solid #d7e3df;
    background-color: unset;
  }

  .react-calendar-timeline .rct-calendar-header {
    border: unset;
  }

  .react-calendar-timeline .rct-header-root {
    background-color: #eef9f5;
    border: 1px solid #d7e3df;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .react-calendar-timeline .rct-sidebar {
    background-color: #eef9f5;
    border: 1px solid #d7e3df;
    border-top: unset;
    border-right: unset;
    width: auto !important;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: unset;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: unset;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: unset;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid #d7e3df;
    border-bottom: 1px solid #d7e3df;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 1px;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid #d7e3df;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: #f9fbfa;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: #f9fbfa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid #d7e3df;
  }

  .react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    z-index: 1;
  }
}
