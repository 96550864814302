.notificationGroupList {
  @apply w-full h-full flex flex-col gap-4 overflow-y-auto;
}

.receivedNotificationList {
  @apply flex flex-col gap-2;
}

.notificationGroupTitle {
  @apply mb-2;
}

.receivedNotificationScroll {
  @apply mr-[6px];
}

.emptyData {
  @apply text-14-400-normal text-mineral-green-600 text-center py-10;
}
