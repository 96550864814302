#baseInputEmailMultiple {
  .MuiOutlinedInput-input {
    @apply p-0;
  }

  .MuiOutlinedInput-notchedOutline {
    @apply border-none;
  }

  .MuiTypography-root {
    @apply mt-[10px] text-12 px-1 text-primary-red-600;
  }

  .MuiFormLabel-asterisk {
    @apply text-primary-red-600;
  }

  .MuiFormLabel-root {
    @apply mb-[10px] text-12-400-normal text-primary-pewter-300 font-aeonik;
  }

  .MuiInputBase-input {
    @apply text-primary-sirocco-500 text-12-400-normal border-primary-geyser-100 outline-none;
  }

  .MuiChip-label {
    @apply text-primary-sirocco-500 text-12-400-normal;
  }

  .MuiInputBase-root {
    padding: 0px !important;
  }
}
