.container {
  @apply bg-white font-aeonik w-full h-full;
}

.headContent {
  @apply flex gap-[10px] items-center mb-[20px];
}

.imageCircleWrap {
  @apply flex;
}

.headName {
  @apply text-burnham-950 text-16-400-normal truncate;
}

.headPosition {
  @apply text-primary-neutral-300 text-14-400-normal truncate;
}

.headButton {
  @apply flex gap-[20px] mb-[40px];
}

.contentBody {
  @apply flex flex-col gap-[20px];
}

.contentWrap {
  @apply flex flex-col gap-[20px] border-b-[1px] border-primary-gray-300 pb-[20px];
}

.contentWrapLastChild {
  @apply flex flex-col gap-[20px];
}

.contentTitle {
  @apply text-primary-teal-950 text-14-400-normal truncate;
  text-transform: uppercase;
}

.contentGroup {
  @apply flex flex-col gap-[16px];
}

.contentLabelValue {
  @apply flex justify-between gap-[20px] w-full;
}

.contentLabel {
  @apply text-primary-neutral-400 text-14-400-normal w-[170px];
}

.contentValue {
  @apply text-primary-teal-950 text-16-400-normal whitespace-nowrap text-right flex-1 cursor-default text-ellipsis overflow-hidden;
}

.tooltipText {
  @apply text-white;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.emptyData {
  @apply text-18-400-22 text-primary-red-600 items-center flex justify-center w-full h-full;
}
