.container {
  @apply p-3 rounded-lg border border-nebula-100 overflow-hidden;
}

.container.unRead {
  @apply bg-primary-teal-800/10;
}

.container:hover {
  @apply bg-alabaster-50 cursor-pointer;
}

.notificationContainer {
  @apply flex items-start ;
}

.notificationIcon {
  @apply w-[24px] h-[24px] ;
}

.notificationContentBox {
  @apply flex-1;
}

.notificationContent {
  @apply w-full;
}

.notificationTitleBox {
  @apply flex justify-between items-center gap-1 mb-[5px];
}

.notificationTitle {
  @apply text-gray-900 text-12-400-normal line-clamp-1 flex-1 font-aeonik;
}

.notificationTime {
  @apply text-gray-900 text-10-400-normal line-clamp-1 font-aeonik;
}

.notificationDescription {
  @apply text-12-400-normal text-gray-600 line-clamp-2;
}