.baseDatePickerContainer {
  @apply w-full;

  &.row {
    @apply flex flex-row items-center gap-[10px];
  }

  &.col {
    @apply flex flex-col gap-[10px];
  }
}

#baseDatePickerComponent .labelField {
  @apply block text-12-400-normal text-primary-pewter-300 truncate;

  &.row {
    @apply overflow-visible;
  }
}

.baseDatePickerContent {
  @apply w-full;
}

// Custom lib
#baseDatePickerComponent {
  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker {
    @apply w-[350px] m-0 font-aeonik text-12-400-normal text-primary-sirocco-500 bg-white rounded-lg p-5;
    @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  }

  // Navigation
  .react-datepicker__navigation {
    @apply top-[22px];
  }

  .react-datepicker__navigation--previous {
    @apply left-5;
  }

  .react-datepicker__navigation--next {
    @apply right-5;
  }

  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon::before {
    @apply border-t-[1.5px] border-r-[1.5px] text-primary-sirocco-500;
  }

  // Current month
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    @apply text-16-500-normal text-primary-sirocco-500 h-8 flex items-center justify-center;
  }

  // Header
  .react-datepicker__header {
    @apply border-none p-0 text-primary-sirocco-500 bg-white;
  }

  // Day of week
  .react-datepicker__day-names {
    @apply inline-flex mt-3 w-full justify-between;
  }

  .react-datepicker__day-name {
    @apply text-primary-sirocco-500 text-12-400-normal m-0;
    @apply flex justify-center items-center w-8 h-8;
  }

  // Day of month
  .react-datepicker__month {
    @apply m-0;
  }

  .react-datepicker__week {
    @apply inline-flex mt-1 w-full justify-between;
  }

  .react-datepicker__day {
    @apply text-primary-sirocco-500 text-12-400-normal rounded-full m-0;
    @apply flex justify-center items-center  w-8 h-8;
  }

  // Offside day
  .react-datepicker__day--outside-month {
    @apply text-primary-gray-300;
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--selected) {
    @apply bg-primary-teal-800/10 text-primary-teal-800 rounded-full;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    @apply text-white;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    @apply relative z-[1] bg-primary-teal-800;
  }

  .react-datepicker-popper {
    top: -2px !important;
    position: fixed !important;
    z-index: 9999 !important;
  }
}

.react-datepicker__day--today {
  @apply bg-primary-teal-800/20 rounded-full m-0;
  @apply flex justify-center items-center  w-8 h-8;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  @apply bg-aqua-haze-50 text-green-pea-700 p-2 z-[2] border border-primary-teal-800/20;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  @apply bg-primary-teal-800/80 text-white transition duration-200 ease-out rounded-[0.3rem];
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  @apply border-primary-sirocco-500;
  border-width: 2px 2px 0 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  @apply rounded-3xl bg-transparent text-black;
}

.react-datepicker__day--today.react-datepicker__day--keyboard-selected {
  @apply rounded-3xl text-black;
  background-color: rgba(14, 155, 126, 0.2) !important;
}

.required {
  @apply text-primary-red-600;
}
