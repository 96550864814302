// Custom Month
.rbc-month-view {
  @apply border border-nebula-100 rounded-lg;
}

.rbc-header {
  @apply border-b-0 h-[58px] flex items-center justify-center text-patina-400 text-16-400-normal;
}

.rbc-header + .rbc-header {
  @apply border-l-nebula-100;
}

.rbc-month-header {
  @apply bg-aqua-squeeze-50 rounded-tl-lg rounded-tr-lg sticky top-0 z-10;
}

.rbc-day-bg + .rbc-day-bg {
  @apply border-l-nebula-100;
}

.rbc-month-row + .rbc-month-row {
  @apply border-t-nebula-100;
}

.rbc-date-cell.rbc-off-range {
  @apply text-jet-stream-200;
}

.rbc-date-cell {
  @apply text-16-400-normal text-watercourse-700;
}

.rbc-off-range-bg,
.rbc-time-header-gutter {
  background: repeating-linear-gradient(135deg, #ffffff, #ffffff 10px, #f2f2f2 12px);
}

// Custom Week
.rbc-time-header {
  @apply sticky top-0 z-10;
}

.rbc-time-header-content {
  @apply h-[58px] bg-aqua-squeeze-50 border-l-nebula-100 rounded-tr-lg;
}

.rbc-time-view {
  @apply rounded-lg border border-nebula-100;
}

.rbc-time-view .rbc-row {
  @apply min-h-[38px];
}

.rbc-time-header.rbc-overflowing {
  @apply border-r-0;
  margin-right: 0 !important;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  @apply bg-white;
}

.rbc-header.rbc-today {
  @apply bg-aqua-squeeze-50 text-stromboli-700;
}

.rbc-time-slot {
  @apply text-14-400-normal text-stromboli-700;
}

.rbc-time-content {
  @apply border-t-0 overflow-hidden;
}

.rbc-time-gutter.rbc-time-column,
.rbc-time-header-gutter {
  @apply min-w-[77px];
}

.rbc-time-header-gutter {
  @apply rounded-lg;
}

.rbc-time-gutter.rbc-time-column {
  @apply bg-aqua-squeeze-50;
}

.rbc-timeslot-group {
  @apply border-b-nebula-100 min-h-[50px] flex justify-center items-center;
}

.rbc-time-content > * + * > * {
  @apply border-l-nebula-100;
}

.rbc-day-slot .rbc-time-slot {
  @apply border-t-0;
}

.rbc-timeslot-group .rbc-time-slot:last-child {
  @apply hidden;
}

.rbc-timeslot-group .rbc-time-slot {
  @apply flex justify-center items-center;
}

.rbc-today {
  @apply bg-white;
}

// Custom Event
.rbc-event-label {
  @apply hidden;
}

.rbc-event {
  @apply bg-white;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  @apply border-none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  @apply bg-white;
}

.rbc-time-view .rbc-row:first-child {
  @apply bg-aqua-squeeze-50 rounded-tr-lg;
}

.rbc-allday-cell {
  @apply hidden;
}
