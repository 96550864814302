.container {
  @apply w-full flex flex-col overflow-hidden;

  :global {
    .searchTitle {
      @apply text-14-400-normal text-primary-pewter-300;
    }

    .inputSearchTag {
      @apply bg-white outline-none w-full border border-primary-geyser-100 rounded-lg text-14-400-normal text-primary-pewter-300 truncate h-[34px];
    }

    .MuiInputBase-root {
      @apply text-14-400-normal truncate text-primary-pewter-300;
    }

    .MuiButtonBase-root {
      @apply text-14-400-normal truncate text-primary-pewter-300 capitalize;
    }
  }
}

.label {
  @apply text-12-400-normal text-primary-pewter-300 inline-block mb-[10px];
}

.required {
  @apply text-primary-red-600;
}

.errorMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.listbox {
  @apply z-[9999] rounded-lg border border-primary-geyser-100 bg-white outline-none p-5 overflow-hidden;
  @apply w-[var(--input-width)] [--anchor-max-height:350px];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.listbox {
  @apply flex flex-col;
  padding: 4px !important;
  overflow-y: auto !important;
  max-height: 350px !important;
}

.option {
  @apply w-full text-12-400-normal text-blue-smoke-450 rounded-lg;
  padding: 8px !important;
}

.option:hover {
  @apply text-primary-teal-800;
  background: rgba(36, 96, 77, 0.1) !important;
}

.noOption {
  @apply z-[9999] rounded-lg border border-primary-geyser-100 bg-white outline-none overflow-hidden items-center;
  @apply w-[var(--input-width)] [--anchor-max-height:350px];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.optionDisable {
  @apply w-full text-14-400-normal text-blue-smoke-450/50;
  pointer-events: none !important;
}

.optionSelected {
  @apply flex items-center justify-between gap-1 rounded-full border border-primary-geyser-100 bg-white w-fit px-2 py-1 cursor-pointer my-1;
}

.optionSelectedLabel {
  @apply flex-1 w-full text-14-400-normal text-blue-smoke-450;
}

.optionSelectedIconClose {
  @apply w-[14px] h-[14px] object-cover rounded-full hover:bg-white transition-all duration-75 ease-linear;
}

.inputSearch {
  @apply flex items-center relative h-full;
}

.searchIconBox {
  @apply mr-[10px] items-center justify-center absolute right-0 cursor-pointer;
}

.searchIcon {
  @apply w-[14px] h-[14px] object-cover;
}

.optionNoData {
  @apply w-full p-2 text-14-400-normal text-blue-smoke-450 text-center bg-white;
}
