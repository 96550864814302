.container {
  @apply flex items-center -space-x-[6px];
}

.avatar {
  @apply w-[28px] h-[28px] rounded-full object-cover border-[1px] border-white;
}

.avatarMore {
  @apply flex items-center justify-center w-[26px] h-[26px] rounded-full bg-green-pea-700 cursor-pointer border-[1px] border-white text-12-700-14;
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white;
  font-size: 12px !important;
  font-weight: 400 !important;
}

// Custom Color Green
.avatar.green {
  @apply border-white-ice-50;
}

.avatarMore.green {
  @apply border-white-ice-50 bg-gossamer-500 text-white;
}

// Custom Color Purple
.avatar.purple {
  @apply border-blue-chalk-200;
}

.avatarMore.purple {
  @apply border-blue-chalk-200 bg-medium-red-violet-600 text-white;
}

// Custom Color Yellow
.avatar.yellow {
  @apply border-quarter-spanish-white-50;
}

.avatarMore.yellow {
  @apply border-quarter-spanish-white-50 bg-equator-300 text-white;
}

// Custom Color Orange
.avatar.orange {
  @apply border-watusi-200;
}

.avatarMore.orange {
  @apply border-watusi-200 bg-burnt-sienna-400 text-white;
}
