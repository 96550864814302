.eventContainer {
  @apply overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1 border-red-400 border;
}

.eventGroup {
  @apply relative;
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.collapseEventContainer {
  @apply px-[10px] border border-skeptic-100;
}

.expandEventContainer {
  @apply p-[6px] flex flex-col gap-y-[6px] h-[95px] rounded-r-2xl border-skeptic-100 border;
}

// Scroll Style
.eventContainer.rct-item::-webkit-scrollbar {
  @apply w-1;
}

.eventContainer.rct-item::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.eventContainer.rct-item::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

.eventName {
  @apply truncate text-12-400-normal text-holly-950;
}

.eventTime {
  @apply truncate mt-[2px] text-10-400-12 text-gray-500;
}

.assigneeContainer {
  @apply mt-auto flex gap-x-1;
}

.avatar {
  width: 26px;
  height: 26px;
  background-color: #a38383;
  border-radius: 100%;
}

// Overwrite css of lib
.eventContainer.rct-item {
  line-height: normal !important;
  background: unset !important;
  border: unset !important;
  color: unset !important;
  font-size: unset !important;
  padding: 6px;
  z-index: 1 !important;
}

.tooltipWrap {
  @apply z-10 max-w-[170px];
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white flex-col flex;
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}
