.container {
  @apply w-full h-full overflow-hidden;
}

.homeSafetyAssessmentWrap {
  @apply w-full h-full overflow-hidden;
  @apply grid grid-cols-[230px_1fr] gap-5;
}

.assessmentContent {
  @apply flex flex-col gap-5 w-full h-full pr-2 overflow-y-auto scroll-smooth;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.assessmentMenuGroup {
  @apply h-full flex flex-col border border-nebula-100 rounded-lg overflow-hidden;
}

.assessmentTab {
  @apply flex-1 py-3 pr-2 overflow-hidden;
}

.assessmentTextMenu {
  @apply w-full text-primary-sirocco-500 cursor-pointer px-2 py-2 rounded-md;
  @apply hover:bg-gray-100;
  @apply transition-all duration-300;
}

.assessmentTextMenuSelected {
  @apply bg-green-pea-700 text-white;
  @apply hover:bg-green-pea-700;
  @apply transition-all duration-300;
}

.assessmentMenuContainer {
  @apply w-full max-h-full grid grid-cols-1 overflow-y-auto scroll-smooth px-3 gap-1;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.carePlanItem {
  @apply p-5 border border-nebula-100 rounded-lg mb-5 overflow-hidden;
  @apply last:mb-0;
}

.carePlanItemTitle {
  @apply text-20-400-30 text-holly-950 max-w-full truncate mb-5;
}

.carePlanItemTitle.noBody {
  @apply mb-0;
}

.carePlanItemBody {
  @apply bg-white;
}

.actionsGroup {
  @apply py-5 pl-5 pr-1 flex items-center gap-3;
}

.noDataAvailable {
  @apply text-14-400-16 text-green-pea-700 flex-1 flex justify-center items-center w-full h-full;
}
