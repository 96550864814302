.container {
  @apply flex flex-col;
}

.inputBaseLabel {
  @apply w-fit mb-[10px] text-primary-pewter-300;
}

.inputBaseLabelRequired {
  @apply text-primary-red-600;
}

.textField {
  @apply w-full min-w-[100px];
}

.inputHasEmail {
  @apply flex-1;
}

.textFieldInput {
  @apply outline-none;
  @apply text-12-400-normal text-primary-sirocco-500 border-none py-0 px-[1px] truncate;
}

.inputContainer {
  @apply flex flex-wrap items-center w-full bg-white overflow-hidden max-h-[150px];
  @apply border rounded-lg border-primary-gray-300 py-[6px] px-[11px];
}

.inputContainerMinHeight {
  @apply items-start;
}

.valueGroup {
  @apply flex-1 overflow-hidden h-full;
  @apply flex-wrap gap-x-[6px] p-0 cursor-text;
}

.valueGroup.valueGroupHasEmail {
  @apply flex-1 overflow-y-auto h-full items-start pr-2;
  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600/30 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.listGroup {
  @apply flex flex-wrap gap-2 max-w-full;
}

.emailContainer {
  @apply rounded-2xl inline-flex items-center gap-x-1 max-w-full;
}

.containerDeleteIcon {
  @apply min-w-4 min-h-4 rounded-full p-[2px] items-center;
}

.deleteIcon {
  @apply w-3 h-3 object-cover;
}

.itemEmail {
  @apply h-[30px] px-1 text-primary-teal-800 text-14-400-normal;
}

.itemEmailDefault {
  @apply bg-primary-teal-800/10;
}

.errorMessage {
  @apply text-12 text-primary-red-600;
}
