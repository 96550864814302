.clientScheduleTab {
  @apply overflow-hidden flex flex-col gap-y-4;
}

.eventContainer {
  @apply w-full h-full gap-[4px] pr-1 rounded-xl p-[6px];
  @apply relative;
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.eventContainer.monthEvent {
  @apply flex justify-center h-[60px];
}

.eventWrap {
  @apply flex flex-1 flex-row gap-2 items-center overflow-y-auto overflow-x-hidden;
}

.assignees {
  @apply flex items-center justify-center ml-1;
}

.firstLastName {
  @apply flex flex-col justify-center truncate overflow-hidden;
}

.eventName {
  @apply w-auto h-auto text-14-400-24 text-white text-ellipsis whitespace-nowrap overflow-hidden;
}

.eventTime {
  @apply truncate mt-[2px] text-8-400-10 text-white overflow-hidden;
}

.tooltipWrap {
  @apply z-10 max-w-[170px];
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white flex-col flex;
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}
