.container {
  @apply fixed inset-0 bg-transparent z-50;
  @apply w-full flex items-center justify-center;
}

.modalEvent {
  @apply relative z-20 flex flex-col w-full max-h-[70vh] max-w-[660px] rounded-lg bg-white p-[30px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)];
  @apply transition duration-300 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
}

.eventScheduleModal {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.emptyData {
  @apply w-full flex justify-center items-center;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-6 bg-white overflow-hidden;
}

.headGroup {
  @apply w-full flex flex-col border-b-0 gap-6;
}

.headGroup.headGroupTab {
  @apply border-b-[1px] border-b-nebula-100;
}

.eventModalTitle {
  @apply text-16-400-normal text-holly-950;
}

.content {
  @apply flex h-full flex-1 flex-col overflow-y-auto;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}

.navTabGroup {
  @apply overflow-x-auto;
}
