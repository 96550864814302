.container {
  @apply w-full h-full flex flex-col gap-y-[30px] overflow-y-hidden;
}

.container.documents {
  @apply overflow-y-auto;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-[20px] overflow-hidden bg-white rounded-[8px] px-[30px] pt-10 pb-[30px];
}

.mainBody.documents {
  @apply min-h-[700px];
}

.headGroup {
  @apply w-full flex justify-between border-b border-b-nebula-100 gap-1;
}

.basicInfoGroup {
  @apply flex max-h-[91px] mb-[30px] gap-[10px] items-center;
}

.nameGroup {
  @apply flex flex-col gap-3;
}

.fullName {
  @apply text-24-400-normal text-holly-950;
}

.valueGroup {
  @apply flex gap-1 text-16-400-normal text-pewter-300 items-center;
}

.value {
  @apply text-mineral-green-600;
}

.navTabGroup {
  @apply self-end;
}

.tabContent {
  @apply h-full overflow-auto flex flex-col;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
