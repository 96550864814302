.careAlertsShowAllContainer {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
  @apply mt-[14px] mb-[20px];
}

.buttonBase {
  @apply flex gap-[10px];
}

.bodyContent {
  @apply flex-1 overflow-y-auto mb-4;
}

.bodyWrapCareAlert {
  @apply grid gap-x-[31px] gap-y-[20px] grid-cols-3 overflow-hidden;
}

.noteCol {
  @apply flex flex-col gap-1;
}

.contentNote {
  @apply text-14-700-normal;
}

.tableLink {
  @apply hover:underline hover:text-blue-smoke-500 cursor-pointer;
  @apply transition-all ease-in-out duration-300;
}

.contentItem {
  @apply flex gap-1;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.contentFilter {
  @apply flex flex-row items-center gap-x-4 justify-between;
}

.filterLabel {
  @apply text-12-400-normal text-holly-950;
}
