.loadingContainer.overlay {
  @apply fixed z-[100] bg-black bg-opacity-25 flex justify-center items-center top-0 left-0 right-0 bottom-0 w-full h-full;
}

.loadingContainer.noOverlay {
  @apply absolute z-[100] flex justify-center items-center top-0 left-0 right-0 bottom-0 w-full h-full;
}

.loadingContent {
  @apply w-12 h-12 inline-block box-border animate-spin rounded-full border-[5px] border-green-pea-700 border-b-transparent border-solid;
}

.small {
  @apply w-7 h-7 border-[3px];
}
