.tableDetail {
  @apply flex-1 mt-6 h-full min-h-[200px];
}

.name {
  @apply capitalize;
}

.link {
  @apply underline text-blue-smoke-500 cursor-pointer;
  @apply hover:text-blue-smoke-500/80;
}
