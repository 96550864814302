.container {
  @apply flex-1 flex flex-col h-full w-full overflow-hidden gap-6;
}
.crmTitle {
  @apply flex items-center text-24-400-normal text-holly-950;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}
