.container {
  @apply w-full h-full overflow-hidden;
}

.form {
  @apply w-full h-full overflow-hidden;
  @apply grid grid-cols-[230px_1fr] gap-5;
}

.quickLinkContent {
  @apply w-full h-full pr-2 overflow-y-auto scroll-smooth;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.quickLinkGroup {
  @apply h-full flex flex-col border border-nebula-100 rounded-lg overflow-hidden;
}

.quickLink {
  @apply flex-1 pl-5 pt-5 pr-1 overflow-hidden;
}

.carePlanItem {
  @apply p-5 border border-nebula-100 rounded-lg mb-5 overflow-hidden;
  @apply last:mb-0;
}

.carePlanItemTitle {
  @apply text-20-400-30 text-holly-950 max-w-full truncate mb-5;
}

.carePlanItemTitle.noBody {
  @apply mb-0;
}

.carePlanItemBody {
  @apply bg-white;
}

.carePlanActions {
  @apply py-5 pl-5 pr-1 flex items-center gap-3;
}
