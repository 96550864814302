.activitiesTimeLineComponent {
  @apply mb-[20px] flex flex-col flex-1 text-14-400-normal text-mineral-green-600;
}

.content {
  @apply grid grid-cols-12;
}

.contentHeadGroup {
  @apply flex flex-col col-span-10;
}

.contentBody {
  @apply flex flex-row items-center overflow-hidden;
}

.imageGroup {
  @apply flex flex-row gap-3 items-center;
}

.dot {
  @apply h-2 w-2 bg-azure-radiance-500 rounded-full;
}

.contentDate {
  @apply col-span-2 text-right;
}

.contentActivities {
  @apply w-full overflow-hidden;
}

.activityDotGroup {
  @apply flex items-center gap-3 mb-1;
}

.activityDotGroup .activityContentGroup {
  @apply ml-0;
}

.activityContentGroup {
  @apply flex-1 flex items-center gap-2 mb-1 ml-5 overflow-hidden;
  @apply last:mb-0;
}

.activityContentLabel {
  @apply text-14-700-normal;
}

.activityContentItem {
  @apply flex-1 truncate;
}
