.container {
  @apply w-full h-full bg-white p-5 rounded flex flex-col gap-6 overflow-hidden;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex-1 overflow-y-auto pr-1;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.form {
  @apply w-1/3 flex flex-col gap-5;
}

.formFooter {
  @apply w-full flex items-center justify-end gap-3;
}
