.container {
  @apply w-full;
}

.customDateHeader {
  @apply flex items-center gap-[10px] justify-between;
}

.prevMonthBtn {
  @apply rotate-90;
}
.nextMonthBtn {
  @apply -rotate-90;
}

.iconControl {
  @apply w-5 h-5;
}

.dateLabel {
  @apply text-14-400-normal text-primary-sirocco-500;
}

.customDateSelect {
  @apply flex items-center justify-center gap-5 w-full mt-2;
}

.customMonthSelector,
.customYearSelector {
  @apply w-[50%] relative;
}

.selectBtn {
  @apply flex items-center w-full justify-between gap-4 cursor-pointer border p-1 rounded-[0.3rem] border-green-pea-700/20;
}

.options {
  @apply absolute top-full left-0 w-full mt-1 p-1 rounded-[5px] border border-primary-teal-800/20 bg-aqua-haze-50 text-green-pea-700 overflow-hidden z-[2] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)];
}

.listOption {
  @apply h-[250px] overflow-y-scroll;
}

.option {
  @apply w-full p-1 mt-1;
}

.option:hover {
  @apply bg-primary-teal-800/80 text-white transition duration-200 ease-out rounded-[0.3rem];
}

.option.active {
  @apply bg-primary-teal-800/80 text-white transition duration-200 ease-out rounded-[0.3rem];
}

.changeMonthBtn,
.changeYearBtn {
  @apply w-full;
}
