.toastContainer {
    @apply relative w-full;
}
  
.toast {
    @apply absolute w-full z-[999] top-[30px];
}
  
.left {
    @apply left-[16px];
}
  
.center {
    @apply left-1/2 transform -translate-x-1/2;
}
  
.right {
    @apply right-[16px];
}
  
.toastItem {
    @apply absolute w-[343px] flex items-center justify-between rounded-lg p-[14px] pr-[14px] bg-white shadow-[0_0_4px_2px_rgba(0,0,0,0.12)] font-aeonik text-14-400-normal text-blue-smoke-400;
}
  
.toastItemContent {
    @apply flex items-center;
}
  
.toastItemLogo {
    @apply bg-white w-[34px] h-[34px] rounded-lg mr-[8px] p-[6px];
}
  
.toastItemTitle {
    @apply text-14-400-normal w-[250px] truncate my-[4px];
}
  
.toastItemDescription {
    @apply text-12-400-normal w-[250px] truncate;
}
  
.toastItemTime {
    @apply text-12-400-normal;
}
  
.toastItemClose {
    @apply cursor-pointer;
}

.toastItemLogo.error {
    @apply  bg-primary-red-600/10;
}

.toastItemLogo.success {
    @apply  bg-green-600/10;
}

.toastItemLogo.warning {
    @apply  bg-orange-400/10;
}

.toastItem.success {
    @apply border-b-4 border-b-green-600;
}
  
.toastItem.warning {
    @apply border-b-4 border-b-orange-400;
}
  
.toastItem.error {
    @apply border-b-4 border-b-primary-red-600;
}