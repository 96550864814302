.label {
  @apply w-fit my-[10px] text-gray-400  text-12-400-normal;
}

.labelRequired {
  @apply pl-1 text-primary-red-600;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}
