.container {
  @apply w-full h-full bg-white rounded-[4px] p-5 overflow-hidden;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-6 overflow-y-auto;
}

.analyticsChartWrap {
  @apply flex flex-col w-full h-full;
}
