.container {
  @apply relative w-full;
}

.timePickerLabel {
  @apply block text-12-400-normal font-aeonik text-primary-pewter-300 text-wrap mb-[10px];
}

.btnSelect {
  @apply w-full h-[34px] flex items-center justify-between gap-2 rounded-md bg-white border border-primary-geyser-100 px-2 py-[7px] outline-none select-none;
}

.bgDisable {
  @apply bg-aqua-squeeze-100;
}

.timeSelected {
  @apply flex-1 w-full text-primary-sirocco-500 text-12-400-normal outline-none text-left truncate;
}

.timePickerIcon {
  @apply w-3 h-3 object-cover;
}

.options {
  @apply z-[9999] rounded-lg border border-primary-geyser-100 bg-white p-3 outline-none flex flex-col overflow-hidden;
  @apply w-[var(--button-width)] [--anchor-max-height:350px];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[6px_26px_54px_0_rgba(0,0,0,0.06)];
}

.optionsHead {
  @apply mb-4;
}

.labelOptions {
  @apply text-16-400-normal text-holly-950 mb-3;
}

.inputBox {
  @apply w-full h-8 rounded-lg px-3 flex items-center gap-2 border border-primary-geyser-100 overflow-hidden;
}

.inputTime {
  @apply appearance-none bg-transparent flex-1 w-full outline-none border-0 text-14-400-normal text-green-pea-700 overflow-hidden;
}

.inputTime::-webkit-calendar-picker-indicator {
  @apply hidden appearance-none;
}

.inputClockIcon {
  @apply w-3 h-3 object-cover;
}

.chooseTimeBox {
  @apply flex-1 flex flex-col overflow-hidden;
}

.chooseTimeLabel {
  @apply text-16-400-normal text-green-pea-700 mb-[10px];
}

.timeOptions {
  @apply flex justify-around flex-1 w-full mb-4 max-h-[250px] flex gap-y-[6px] flex-wrap overflow-y-auto pr-1;
}

.timeOptions::-webkit-scrollbar {
  @apply w-1 pr-1;
}

.timeOptions::-webkit-scrollbar-thumb {
  @apply bg-mineral-green-600 rounded-lg cursor-pointer;
}

.timeOptions::-webkit-scrollbar-track {
  @apply rounded-full bg-alto-200;
}

.timeOption {
  @apply min-w-[90px] rounded-[7px] border border-primary-geyser-100 py-[5px] inline-flex items-center justify-center cursor-pointer;
  @apply transition-all ease-linear duration-75;
  @apply hover:bg-green-pea-700/10 text-green-pea-700;
}

.timeOptionContent {
  @apply text-12-400-normal text-green-pea-700;
}

.timeOptionContent.active {
  @apply text-white;
}

.timeOption.active {
  @apply bg-green-pea-700;
}

.timeActions {
  @apply flex flex-row flex-wrap gap-2;
}

.button {
  @apply flex-1;
}

.required {
  @apply text-primary-red-600;
}

// Err Message
.errMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}
