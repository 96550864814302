.checkboxMultipleComponent {
  @apply w-full flex gap-[10px];
}

.multipleCheckboxGroup {
  @apply flex gap-y-[10px] gap-x-5 flex-wrap;
}

.multipleCheckboxContent {
  @apply flex items-center gap-[6px];
}

.multipleCheckbox {
  @apply border-primary-neutral-400 cursor-pointer size-4 border rounded-sm flex items-center justify-center;
}

.multipleCheckbox.checked {
  @apply bg-green-pea-700 border-transparent;
}

.checkboxName {
  @apply text-primary-sirocco-500 text-12-400-normal line-clamp-1 truncate break-words whitespace-normal;
}

.inputBaseMessageError {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.label {
  @apply inline-block text-12-400-normal text-primary-pewter-300 capitalize font-aeonik;
}

.required {
  @apply text-primary-red-600;
}
