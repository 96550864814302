.container {
  @apply relative w-full max-w-[500px];
}

.title {
  @apply text-36-500-46 text-holly-950 mb-[10px];
}

.desc {
  @apply text-16-400-26 text-mineral-green-600 mb-[30px];
}

.formForgotPassword {
  @apply flex flex-col gap-[30px];
}

.label {
  @apply inline-block mb-[10px];
  @apply text-12-400-normal text-primary-neutral-400;
}

.inputGroup {
  @apply relative flex items-center gap-2 overflow-hidden;
  @apply w-full h-9;
  @apply rounded-lg border border-primary-geyser-100;
}

.input {
  @apply flex-1 w-full border-0 outline-none;
  @apply px-9 py-[10px];
  @apply text-12-400-16 text-primary-sirocco-500 truncate;
}

.inputPrefix,
.inputSuffix {
  @apply w-4 h-4 overflow-hidden;
  @apply absolute top-1/2 -translate-y-1/2;
}

.inputPrefix {
  @apply left-3;
}

.errorMessage {
  @apply min-h-4 px-2 text-primary-red-600 text-12-400-normal left-0 top-full mt-[10px];
}
