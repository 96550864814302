.hrDetailComponent {
  @apply w-full h-full flex flex-col gap-5 overflow-y-auto;
}

.bodyWarp {
  @apply w-full h-full flex gap-6 mb-5;
}

.infoDetailsWrap {
  @apply mb-6 w-full flex flex-col gap-3 pt-3;
}

.infoTabComponent::-webkit-scrollbar {
  @apply w-1;
}

.noteItem {
  @apply flex gap-x-2 mt-2;
}

.label {
  @apply w-[4px] h-[4px] bg-primary-teal-950 ml-2 flex-shrink-0 mt-3 rounded-full;
}

.noteContent {
  @apply text-14-400-24 text-gray-600 font-aeonik;
}
