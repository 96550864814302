.radioCrmContainer {
  @apply flex items-center gap-2;
}

.radioCrmWrap {
  @apply w-[16px] h-[16px] flex items-center justify-center;
}

.radioInput {
  @apply appearance-none w-[16px] h-[16px] cursor-pointer rounded-[50%] border-[1px] border-solid border-primary-neutral-400 bg-white relative;
}

.radioInput:checked {
  @apply border-azure-radiance-500 bg-white;
}

.radioInput:checked::before {
  @apply content-[''] block w-2 h-2 bg-azure-radiance-500 absolute -translate-x-2/4 -translate-y-2/4 rounded-[50%] left-2/4 top-2/4;
}

.radioCrmLabel {
  @apply text-primary-sirocco-500 text-12-400-normal whitespace-nowrap cursor-pointer;
}

.radioCrmLabel.disabled {
  @apply cursor-default text-primary-teal-800/50;
}
