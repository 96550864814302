.dropdownMenu {
  @apply bg-white min-w-[180px] flex flex-col gap-1 p-4 rounded-lg border-gray-200 border-[1px] shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.dropdownSubMenu {
  @apply pl-6;
}

.subMenu {
  @apply fixed z-50 bg-transparent;
}
