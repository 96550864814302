.modalLayer {
  @apply bg-primary-nandor-600 bg-opacity-[78%] fixed top-0 left-0 inset-0 w-full z-40;
}

.modalWrap {
  @apply fixed z-50 flex w-full justify-center items-center overflow-hidden inset-0;
}

.modalChildren {
  @apply relative w-[780px] max-h-[85%] bg-white h-fit rounded-[8px] flex;
}

.headerModalButton {
  @apply absolute right-3 top-3 cursor-pointer;
}

.modalContent {
  @apply px-[30px] pb-[30px] mt-[30px] flex flex-col w-full overflow-hidden;
}

.modalTitle {
  @apply border-b border-primary-geyser-100 pb-[30px] font-aeonik text-24-400-normal text-green-950;
}

.children {
  @apply flex-1 pt-[20px] flex flex-col overflow-hidden relative;
}
