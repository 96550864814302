.invoiceExpired {
  @apply flex flex-row px-4 py-[6px] bg-primary-red-200 mb-3 rounded items-center gap-[6px];
}

.invoiceExpiredText {
  @apply text-14-400-normal underline py-2 text-primary-red-500 cursor-pointer;
}

.icon {
  @apply w-[22px] h-[22px] object-cover;
}
