.infinityScrollComponent {
  @apply flex flex-col h-full w-full;

  :global {
    .infinite-scroll-component__outerdiv {
      @apply h-full;
    }

    .infinite-scroll-component {
      @apply mt-2 pr-2;
    }

    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply bg-mineral-green-600 rounded-lg cursor-pointer;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full bg-alto-200;
    }
  }
}

.textNoData {
  @apply text-14-400-16 text-green-pea-700 items-center flex justify-center w-full h-full;
}
