.container {
  @apply w-full h-full flex flex-col overflow-y-auto gap-5 pr-2;
  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.contentText {
  @apply flex flex-col gap-4;
}

.title {
  @apply text-16-400-normal text-green-pea-700;
}
