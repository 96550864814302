.prospectInformationInitialCall {
  @apply p-5 gap-5 rounded-[4px] border border-nebula-100 w-full flex flex-col;
}

.headTitle {
  @apply text-16-400-normal text-holly-950;
}

.bodyWrap {
  @apply flex flex-col gap-5;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}
