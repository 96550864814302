.dailyScheduleComponent {
  @apply h-full flex flex-col overflow-hidden;
}

.toolbarWrapper {
  @apply flex items-center justify-between mb-5;
}

.toolbarLeft {
  @apply flex items-center gap-2;
}

.dateControl {
  @apply flex items-center gap-[9px] py-[7px] px-[6px] bg-green-pea-700 rounded-lg h-7;
}

.dateControlBtn {
  @apply h-[14px] w-[14px];
}

.dateControlBtnToday {
  @apply text-14-400-normal text-mineral-green-600 truncate border h-7 px-[9px] py-[6px] rounded-lg flex items-center justify-center border-athens-gray-100 bg-white font-aeonik;
}

.label {
  @apply text-20-400-normal text-mineral-green-600 truncate font-aeonik;
}

.toolbarRight {
  @apply flex align-middle gap-3;
}

.btnCalendarView {
  @apply text-16-400-normal text-green-pea-700 cursor-pointer;
}

.btnGroup {
  @apply min-w-0;
}

.btnCalendar {
  @apply text-mineral-green-600 text-14-400-normal truncate rounded-lg bg-none px-3 py-[5px] font-aeonik;
}

.btnCalendar.active {
  @apply bg-white text-green-pea-700;
}

.bodyWrap {
  @apply h-full w-full flex flex-col overflow-hidden rounded-lg bg-white;
}

// Schedule container
.scheduleWrap {
  @apply flex-1 overflow-y-auto h-full;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

// Right Header Component
.modeViewCalendar {
  @apply bg-aqua-haze-50 rounded-lg p-1;
}

.btnCalendar {
  @apply text-mineral-green-600 text-14-400-normal truncate rounded-lg bg-none px-3 py-[5px] font-aeonik;
}

.btnCalendar.active {
  @apply bg-white text-green-pea-700;
}
