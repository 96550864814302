.container {
  @apply w-full h-full flex flex-col gap-y-[30px] overflow-y-hidden;
}

.container.files {
  @apply overflow-y-auto;
}

.backTag {
  @apply w-fit flex flex-row cursor-pointer;
}

.textBack {
  @apply text-14-400-16 text-teal-800;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-[20px] overflow-y-auto bg-white rounded-[8px] px-[30px] pt-10 pb-[30px];
}

.mainBody.files {
  @apply min-h-[700px];
}

.headGroup {
  @apply w-full flex justify-between border-b border-b-nebula-100;
}

.basicInfoGroup {
  @apply flex max-h-[91px] mb-[30px];
}

.avtImage {
  @apply h-[91px] w-[91px] bg-gray-200 rounded-full;
}

.basicInfo {
  @apply ml-[10px] flex flex-col w-[370px] justify-start gap-1;
}

.nameGroup {
  @apply flex;
}

.icons {
  @apply ml-3 flex gap-x-[6px] cursor-pointer;
}

.iconMessage {
  @apply bg-aqua-squeeze-101 rounded-full w-7 flex justify-center items-center;
}

.jobRoleGroup {
  @apply mt-3 line-clamp-2;
}

//
.navTabGroup {
  @apply self-end;
}

.tabContent {
  @apply flex flex-col h-full overflow-auto;
}

.tabContent::-webkit-scrollbar {
  @apply hidden;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}

.tags {
  @apply flex text-16-400-normal;
}

.tagsLabel {
  @apply text-primary-neutral-400;
}

.tagLocation {
  @apply ml-2 truncate text-primary-teal-950;
}

.referredBy {
  @apply flex items-center text-16-400-normal text-primary-neutral-400 gap-2;
}

.referredLink {
  @apply ml-2 max-w-[200px] text-finn-900 truncate;
}

.tooltipText {
  @apply z-[999] p-2 w-auto;
}

.tooltipTags {
  @apply gap-x-1 leading-6;
}

.tooltipTag {
  @apply text-left truncate;
}

.value {
  @apply text-mineral-green-600;
}
