.crmAddScheduleModal {
  @apply flex flex-col max-h-[100%] font-aeonik overflow-hidden;
}

.contentModal {
  @apply overflow-y-auto flex-1 gap-5 flex flex-col pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.contentModal.hidden-scrollbar {
  scrollbar-width: none;
}

.contentModal.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.fourItemRow {
  @apply flex flex-row gap-[10px] w-full;
}

.textTo {
  @apply mt-8 text-primary-teal-950 text-12-400-normal;
}

.twoItemRow {
  @apply flex flex-row gap-5 w-full;
}

.twoRowCheckbox {
  @apply flex flex-row gap-4 w-full items-center h-[35px] self-end;
}

.twoRow {
  @apply flex flex-row gap-[6px] w-full items-center;
}

.twoRowRadio {
  @apply w-[82px] flex flex-row gap-[6px];
}

.textAllDay {
  @apply w-[130px] text-primary-sirocco-500 text-12-400-normal;
}

.textLabel {
  @apply text-primary-sirocco-500 text-14-400-normal whitespace-nowrap;
}

.textLabelBold {
  @apply text-primary-teal-800 text-14-500-16 whitespace-nowrap;
}

.contentWrap {
  @apply flex flex-col gap-4;
}

.contentRadio {
  @apply flex flex-row gap-[20px] w-full;
}

.contentWrapRadio {
  @apply flex flex-row w-full h-[35px] rounded-lg border-[1px] border-primary-geyser-100 outline-none p-3 items-center;
}

.wrapRadio {
  @apply flex flex-col gap-[10px] w-full;
}

.radioGroup {
  @apply flex flex-row gap-10 w-full;
}

.textButton {
  @apply text-primary-teal-950 text-14-500-16 cursor-pointer;
}

.inputCheckbox {
  @apply w-[13px] h-[13px] border-[1px] border-solid border-primary-neutral-400 bg-white;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[35px];
}
