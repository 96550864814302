.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
  @apply mb-3;
}

.buttonBase {
  @apply flex gap-[10px];
}

.baseTable {
  @apply flex-1 overflow-y-auto mb-4;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.contentFilter {
  @apply flex flex-row items-center justify-between;
}

.statusLabel {
  @apply text-12-400-normal text-holly-950;
}
