.container {
  @apply z-30 w-fit;
}

.defaultSelect {
  @apply border z-30 w-full p-2 justify-between items-center flex flex-row rounded-lg border-primary-gray-300 text-primary-teal-800 text-14-400-normal bg-white gap-1;
}

.optionsSelect {
  @apply border flex flex-col rounded-lg border-primary-gray-300 z-30 bg-white mt-1 w-full;
}

.valueSelect,
.noData {
  @apply data-[focus]:bg-primary-teal-800/20 border-t text-primary-teal-800 text-14-400-normal cursor-pointer p-2;

  &:first-child {
    @apply border-t-0;
  }
}

.textSelect,
.valueSelect {
  @apply truncate;
}

.noData {
  @apply text-center cursor-default;
}

.defaultSelect.primary,
.valueSelect.primary,
.noData.primary {
  @apply text-12-400-normal p-[10px];
}
