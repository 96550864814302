.dateBlock {
  @apply flex flex-col justify-center items-center border-l bg-aqua-squeeze-50 border-nebula-100;
}

.dateText {
  @apply text-16-400-normal text-patina-400;
}

.dateOfWeek {
  @apply font-normal;
}
