.pageNavigator {
  @apply w-full flex justify-between items-center;
}

.pageNavigator.pageNavigatorCenter {
  @apply justify-center;
}

.buttonWrap {
  @apply w-fit flex justify-center items-center;
}

.pageButton {
  @apply flex justify-center items-center w-[67px] h-[37px] text-14-400-normal text-green-pea-700 border-nebula-100 border;
}

.pageButton:first-child {
  @apply rounded-tl-[6px] rounded-bl-[6px];
}

.pageButton:last-child {
  @apply rounded-tr-[6px] rounded-br-[6px];
}

.pageButton:not(:first-child):not(:last-child) {
  @apply border-x-0 w-fit py-[10px] px-[18px];
}

.pageButton.disabled {
  @apply pointer-events-none cursor-default opacity-50;
}

.threeDotStyle {
  @apply text-neutral-700 text-10-700-11;
}

.active {
  @apply text-white bg-burnham-950 border border-burnham-950;
}

.leftChevronIcon {
  @apply rotate-180;
}

.showResult {
  @apply text-16-400-normal text-mineral-green-600;
}
