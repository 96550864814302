.baseTimePickerContainer {
  @apply w-full;
}

#baseTimePickerComponent .labelField {
  @apply block mb-[10px] text-12-400-normal text-primary-pewter-300 truncate;
}

.baseTimePickerContent {
  @apply w-full;
}

// Custom lib
#baseTimePickerComponent {
  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker {
    @apply w-[90px] m-0 font-aeonik text-12-400-normal text-primary-sirocco-500 bg-white rounded-lg pr-1 py-5;
    @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  }

  .react-datepicker__header {
    @apply hidden;
  }

  .react-datepicker__time-container {
    @apply w-auto;
  }

  .react-datepicker__time-box {
    @apply w-auto;
  }

  .react-datepicker__time-list {
    box-sizing: border-box !important;
    @apply flex flex-col justify-around gap-[5px] pr-4;
  }

  .react-datepicker__time-list-item {
    @apply h-auto inline-block text-center text-12-400-normal text-shadow-green-950 rounded;
  }

  .react-datepicker__time-list-item--selected {
    @apply bg-primary-teal-800/10 text-primary-teal-800;
  }
}

// Scroll bar
#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar {
  @apply w-1;
}

#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-pewter-300;
}

#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-gray-300;
}

.required {
  @apply text-primary-red-600;
}
