.container {
  @apply relative h-screen min-w-[1440px] pt-6 pb-[26px] bg-aqua-haze-50 overflow-y-hidden overflow-x-auto;
}

.mainContent {
  @apply w-full h-full flex flex-col gap-y-10 max-w-[1342px] mx-auto overflow-hidden;
}

.headerWrap {
  @apply w-full;
}

.bodyWrap {
  @apply flex-1 overflow-hidden relative;
}

// Hidden Scrollbar Y
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  @apply w-0 h-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply rounded-lg bg-alto-200;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-mineral-green-600 rounded-lg;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.versionText {
  @apply absolute right-4 bottom-4 text-14-400-normal text-green-pea-700;
}
