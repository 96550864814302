.taskModalComponent {
  @apply flex flex-col max-h-[100%] font-aeonik;
}

.contentModal {
  @apply overflow-y-auto flex-1 gap-5 flex flex-col pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[40px];
}

.textDevelopment {
  @apply text-18-400-22 text-primary-red-600 items-center flex justify-center w-full h-[200px];
}
