.container {
  @apply w-full flex flex-col gap-5 text-14-400-normal ;
}

.threeCol {
  @apply grid grid-cols-3 gap-5 items-center;
}

.label {
  @apply  text-primary-pewter-300 text-12-400-normal font-aeonik mb-[10px];
}




