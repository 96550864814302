.container {
    @apply flex flex-col;
}
  
// Label
.label {
    @apply mb-[10px] text-12-400-normal text-primary-pewter-300 capitalize font-aeonik;
}
  
// Select
.btnSelect {
    @apply min-w-[140px] flex justify-between items-center gap-x-1 p-[10px] bg-white border border-primary-geyser-100 rounded-lg;
}
  
.btnPlaceholder {
    @apply text-12-400-normal truncate text-primary-sirocco-500 capitalize font-aeonik;
}
  
.btnText {
    @apply text-12-400-normal text-primary-sirocco-500 truncate;
}
  
.iconActive {
    @apply rotate-180;
}
  
// Option
.optionList {
    @apply z-[1000] rounded-lg border border-primary-geyser-100 bg-white w-[var(--button-width)] h-[350px] flex flex-col;
    overflow: hidden !important;
}
  
.optionItem {
    @apply w-full text-14-400-normal text-blue-smoke-400 cursor-pointer flex flex-col justify-center py-4 px-2;
}

.optionItem:last-child {
    @apply border-none;
}

.line {
    @apply h-[1px] bg-primary-geyser-100;
}

.line:last-child {
    @apply h-0 bg-none;
}
  
.optionActive {
    @apply bg-white text-primary-teal-800;
}
  
.optionHover {
    @apply bg-primary-geyser-100 text-primary-teal-800;
}
  
// No data
.optionNoData {
    @apply w-full p-[8px] text-14-400-normal text-primary-sirocco-500 rounded-lg mt-[2px];
}
  
// Err Message
.errMessage {
    @apply mt-1 text-10 px-1 text-primary-red-600;
}

.optionItemHeader {
    @apply flex flex-col gap-3 px-4 pt-4;
}

.titleOption {
    @apply text-16-400-normal text-burnham-950;
}

.optionItems {
    @apply overflow-y-scroll px-4 py-2 h-full w-full;
}