.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik gap-y-6;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.controlWrap {
  @apply w-[400px] flex items-center gap-[10px];
}
