.overtimeSettingsComponent {
  @apply flex flex-col items-center w-full;
}

.content {
  @apply w-full break-words flex flex-col gap-y-4 text-teal-800 text-16-500-normal border border-primary-geyser-100 rounded-lg p-6;
}

.contentTable {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.headerContent {
  @apply flex justify-between items-center;
}

.title {
  @apply text-holly-950 font-semibold text-16-400-normal;
}
