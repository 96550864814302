.container {
  @apply p-5 w-full h-full;
}

.headerChart {
  @apply flex items-center justify-between gap-[10px];
}

.title {
  @apply text-20-400-normal text-black;
}

.columnChart {
  @apply mt-5;
}

.noData {
  @apply flex w-full h-full justify-center items-center;
}
