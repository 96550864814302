.notesEventTab {
  @apply flex flex-col flex-1 max-h-[100%] font-aeonik bg-white overflow-hidden;
}

.container {
  @apply overflow-hidden flex-1 gap-y-2 flex flex-col bg-white pr-2;
}

.tabNotesTitle {
  @apply text-18-400-28 text-primary-teal-950 font-aeonik;
}

.tabNotesContainer {
  @apply flex flex-col gap-y-[10px] overflow-y-auto;
}

.tabNotesContent {
  @apply text-16-400-26 text-gray-600 font-aeonik;
}

.noteContent {
  @apply text-14-400-24 text-gray-600 font-aeonik;
}

.noteListWrapper {
  @apply max-h-[70px] overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.tabNotes {
  @apply mt-1;
}

.border {
  @apply w-full h-[1px] bg-nebula-100 mt-5;
}

.footerButton {
  @apply flex justify-end mt-6;
}

.noteItem {
  @apply flex gap-x-2 mt-2;
}

.label {
  @apply w-[4px] h-[4px] bg-primary-teal-950 ml-2 flex-shrink-0 mt-3 rounded-full;
}
