.originStyle {
  @apply w-full flex justify-center items-center px-3 py-[2px] rounded-[5px] bg-opacity-[20%] bg-primary-teal-800 text-14-400-normal font-aeonik;
}

.originStyle.teal800 {
  @apply text-white bg-primary-teal-800;
}

.originStyle.red600 {
  @apply text-white bg-primary-red-600;
}
