.btnGroup {
  @apply flex justify-center items-center p-2 gap-[7px] rounded-lg bg-white border border-gray-200 focus:outline-none text-14-400-normal font-aeonik text-primary-neutral-600;
}

.btnImg {
  @apply h-[14px] w-[14px];
}

.popupFilterContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white p-5 z-20 min-w-[260px] rounded-lg border shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  overflow: hidden !important;
}

.popTitle {
  @apply text-burnham-950 text-16-500-normal;
}

// Button
.popBtnGroup {
  @apply flex items-center justify-between gap-[10px];
}

.popBtnApply {
  @apply flex items-center justify-center;
  @apply h-[36px] w-full px-[10px] rounded-lg;
  @apply text-white bg-green-pea-700 text-14-400-16;
  @apply hover:bg-green-pea-700/70 transition;
  @apply disabled:bg-green-pea-700/70 disabled:border-none;
}

.popContent {
  @apply max-w-[700px] min-w-[236px] max-h-96 my-4 overflow-y-auto;
}

.emptyData {
  @apply w-full flex justify-center items-center;
}
