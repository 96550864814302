@tailwind base;
@tailwind components;
@tailwind utilities;

$assets-path: '../../../assets/fonts';

// Config Font
@font-face {
  font-family: 'Aeonik';
  font-weight: 400;
  src: url('#{$assets-path}/Aeonik/Aeonik.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Aeonik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: 'Aeonik', sans-serif;
}
