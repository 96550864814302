.container {
  @apply flex flex-row gap-[10px] w-fit h-[59px] border rounded-lg border-nebula-100 p-[12px] relative overflow-hidden;
}

.imageCircleWrap {
  @apply w-[35px] h-[35px] flex justify-center items-center;
}

.infoContent {
  @apply flex flex-col gap-1;
}

.name {
  @apply text-green-950 text-16-400-normal truncate;
}

.tagName {
  @apply text-gray-400 text-12-400-normal truncate;
}
