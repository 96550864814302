.addTimeClockComponent {
  @apply flex justify-center items-center font-aeonik max-h-[100%];
}

.contentModal {
  @apply flex flex-1 flex-col max-h-[100%];
}

.bodyModal {
  @apply flex flex-1 flex-col gap-y-5 overflow-y-scroll  pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.footerModal {
  @apply flex justify-end gap-3 mt-10;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}

.textLabel {
  @apply w-fit mb-[10px] text-12-400-normal text-primary-pewter-300;
}

.mapView {
  @apply flex flex-col h-[500px] w-full gap-[10px] rounded-[8px] overflow-auto border border-nebula-100 p-[10px];
}

.label {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik;
}
