.addAssigneeContainer {
  @apply fixed w-[350px] z-[1000];
}

.container {
  @apply max-h-[375px] bg-white p-4 overflow-hidden flex flex-col rounded-lg;
  @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.assignee {
  @apply flex flex-col overflow-y-scroll mt-[2px] pr-2;
  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.assigneeItem {
  @apply flex items-center justify-between py-4 px-2 mt-[2px];
}

.assigneeItem.selected {
  @apply bg-green-pea-700/10;
}

.avatarFirstLastName {
  @apply flex items-center gap-[10px];
}

.nameGroup {
  @apply flex flex-col gap-[2px];
}

.firstLastName {
  @apply truncate text-16-400-normal w-[150px];
}

.email {
  @apply text-14-400-16 text-neutral-400 text-ellipsis overflow-hidden whitespace-nowrap w-[150px];
}

.titleSelect {
  @apply text-12-400-normal text-gray-400 mb-[10px];
}

.selectAssign {
  @apply w-full border rounded-[8px] h-[34px] flex flex-row  px-[10px] border-gray-300 justify-between items-center cursor-pointer;
}

.titleAssignee {
  @apply text-12-400-normal text-gray-500;
}

.assigneeSelected {
  @apply flex flex-row items-center gap-1 mt-[11px];
}

.tagAssigneeSelected {
  @apply flex flex-row items-center min-w-[71px] border-[1px] rounded-[100px] h-5 pl-[2px] pr-1 gap-1 bg-gray-200;
}

.tagFirstName {
  @apply text-12-400-normal;
}

.iconCloseAssignee {
  @apply w-[6px] h-[6px] ml-auto cursor-pointer;
}

.tagFirstName {
  @apply text-10;
}

.tagMore {
  @apply flex items-center justify-center w-[26px] h-[26px] rounded-full  cursor-pointer border-[1px] border-white-ice-50 bg-gossamer-500 text-white text-12-700-14;
}

.textNoData {
  @apply text-12-400-normal py-4 text-neutral-500 w-full text-center;
}

.lineBottom {
  @apply border-b-[1px] border-paris-white-100;
}

.errMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.bgDisable {
  @apply bg-green-pea-700/10;
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white absolute;
  font-size: 12px !important;
  z-index: 9999 !important;
  font-weight: 400 !important;
}
