.container {
  @apply w-fit;
}

.notificationGroup {
  @apply relative w-10 h-10;
}

.notificationIcon {
  @apply w-full h-full rounded-full bg-white flex items-center justify-center outline-none;
  @apply hover:bg-green-pea-700/15;
  @apply transition-all duration-150;
}

.notificationIconImg {
  @apply block w-5 h-5 object-cover;
}

.notificationQuantity {
  @apply absolute top-0 left-full -ml-4 h-4 min-w-4 rounded-full px-1 w-fit bg-crimson-600 flex items-center justify-center z-10 cursor-default select-none pointer-events-none;
  @apply text-white text-12-400-16;
}

.notificationList {
  @apply w-[392px] max-w-full bg-white rounded-xl p-4 shadow-[0_0_4px_2px_rgba(0,0,0,0.12)] z-30 overflow-hidden;
  @apply data-[closed]:scale-95 data-[closed]:opacity-0;
  @apply transition duration-200 ease-in-out;
}

.notificationGroupList {
  @apply w-full h-full flex flex-col gap-4 overflow-y-auto;
}

.emptyData {
  @apply text-14-400-normal text-mineral-green-600 text-center py-10;
}

.notificationWrap {
  @apply w-full;
}

.receivedNotificationList {
  @apply flex flex-col gap-2;
}

.notificationGroupTitle {
  @apply mb-2;
}

.receivedNotificationScroll {
  @apply mr-[6px];
}

// Scroll bar
.receivedNotificationContainer {
  :global {
    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply rounded-full text-mineral-green-600;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full text-mineral-green-600;
    }
  }
}
