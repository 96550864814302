.container {
  @apply w-full h-full flex flex-col;
}

.quillEditorGroup {
  @apply flex-1 flex flex-col w-full h-full;
}

.quill {
  @apply flex flex-col flex-1 rounded-b-md;
}

.label {
  @apply text-12-400-normal text-primary-pewter-300 capitalize mb-[10px];
}

.required {
  @apply text-primary-red-600;
}

.error-text {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}
