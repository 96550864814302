.customEventMonth {
    @apply w-full h-full bg-white-ice-50 border border-skeptic-100 rounded-[4px] py-[5px] px-[10px] flex flex-col gap-[10px];
}

.customEventMonthWrap {
    @apply overflow-y-scroll flex flex-col gap-[26px];
}

.customEventMonthWrap::-webkit-scrollbar {
    @apply hidden;
}

.titleGroup {
    @apply text-holly-950 text-12-400-normal whitespace-normal flex flex-col gap-[2px];
}

.title {
    @apply text-holly-950 text-12-400-normal whitespace-normal line-clamp-1;
}

.assignee {
    @apply w-full;
}

.eventTime {
    @apply text-mineral-green-600 text-10-400-12;
}

.customEventMonth.green {
    @apply w-full h-full bg-white-ice-50 border border-skeptic-100 rounded-[4px] py-[5px] px-[10px] flex flex-col gap-[10px];
}

// Custom Color Purple
.customEventMonth.purple {
    @apply w-full h-full bg-blue-chalk-200 border border-moon-ranker-300 rounded-[4px] py-[5px] px-[10px] flex flex-col gap-[10px];
}

// Custom Color Yellow
.customEventMonth.yellow {
    @apply w-full h-full bg-quarter-spanish-white-50 border border-parchment-100 rounded-[4px] py-[5px] px-[10px] flex flex-col gap-[10px];
}

// Custom Color Orange
.customEventMonth.orange {
    @apply w-full h-full bg-watusi-200 border border-bizarre-200 rounded-[4px] py-[5px] px-[10px] flex flex-col gap-[10px];
}