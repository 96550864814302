.clientContact {
  @apply w-full flex flex-col gap-5;
}

.headTitle {
  @apply text-16-400-normal text-holly-950 pb-1 border-b border-b-nebula-100;
}

.fieldClientContactBody {
  @apply w-full flex flex-col gap-5;
}

.fieldContent {
  @apply w-full flex justify-between gap-[10px];
}

.threeCol {
  @apply flex-1 grid grid-cols-3 gap-5;
}

.btnRemoveField {
  @apply mt-8 w-4 h-4;
}

.btnAddField {
  @apply flex items-center gap-x-1 w-fit;
  @apply transition-all duration-300 ease-in-out hover:opacity-70;
}

.btnTextAdd {
  @apply text-12-400-normal text-primary-teal-800;
}
