.trainingTabComponent {
  @apply w-full h-full overflow-y-auto pr-2 flex flex-col gap-5;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.trainingTab {
  @apply flex flex-col gap-5;
}

.heading {
  @apply text-20-400-normal text-holly-950;
}

.trainingWrap {
  @apply grid grid-cols-3 gap-10;
}

.infoTraining {
  @apply flex flex-col gap-5 col-span-2;
}

.certification {
  @apply w-[400px] h-[250px] cursor-pointer;
}

.certificationImg {
  @apply w-full h-full;
}

.line {
  @apply w-full h-[2px] bg-primary-teal-800 rounded-full;
}

.errorSection {
  @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] border rounded-lg border-nebula-100 p-5 flex flex-col gap-5 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.warningTitle {
  @apply text-20-400-normal border-b pb-1;
}
