.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 py-5 overflow-hidden;
}

.messageContent {
  @apply flex w-full h-full overflow-hidden border border-nebula-100 rounded-[4px];
}

.messageMenu,
.messageConversation {
  @apply p-[30px];
}

.messageMenu {
  @apply w-[35%] border-r-[1px] border-nebula-100 overflow-hidden;
}

.messageConversation {
  @apply w-[65%] flex items-center justify-center overflow-hidden flex-1;
}

.conversationNotSelected {
  @apply text-14-400-normal text-holly-950;
}
