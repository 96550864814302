.baseTableComponent {
  @apply relative flex flex-col w-full h-full flex-1 bg-white overflow-auto gap-1;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.baseTableComponent::-webkit-scrollbar-thumb {
  @apply cursor-pointer;
}

.tableContainer {
  @apply w-full text-left table-auto;
}

// Expand, collapse table
.buttonExpand {
  @apply flex flex-row gap-1 py-3 rounded-md items-center px-1;
  @apply text-holly-950 font-aeonik text-20-400-normal text-start;
}

.buttonExpand.collapsed {
  @apply border border-primary-geyser-100 bg-saltpan-50;
}

.iconExpand {
  @apply w-6 h-6 object-cover transition-transform duration-300 ease-in-out;
}

.iconExpand.rotate {
  @apply rotate-180;
}

// Table header
.colTable {
  @apply py-[10px] text-left sticky top-0 bg-saltpan-50 uppercase text-14-400-normal text-burnham-950 border-b border-b-nebula-100 first:pl-5 last:pr-5 z-20 pr-2;
}

.colTable::after {
  @apply content-[""] absolute left-0 bottom-0 w-full;
}

// Table body
.rowTableBody {
  @apply hover:bg-alabaster-50;
  @apply transition-all duration-0 ease-in-out;
}

.rowTableBody.rowClickable {
  @apply cursor-pointer;
}

.colTableBody {
  @apply py-[7px] text-14-400-normal text-mineral-green-600 break-words whitespace-normal border-b border-b-nebula-100 first:pl-5 last:pr-5 pr-2;
}

.cellContainer {
  @apply flex items-center;
}

.noDataAvailableExpand {
  @apply flex justify-center items-center min-h-[70px] w-full;
}

.noDataAvailable {
  @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
}
