.container {
  @apply flex flex-col max-h-[100%] font-aeonik;
}

.contentModal {
  @apply overflow-y-auto flex flex-col gap-5 pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.viewTime {
  @apply grid grid-cols-[1fr_1fr_0.6fr] gap-5;
}

.twoColumn {
  @apply grid grid-cols-2 gap-5;
}

.formActions {
  @apply flex justify-end gap-3 pt-5;
}
