.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden;
}

.bodyWrap {
  @apply h-full w-full flex flex-col overflow-hidden gap-4;
}

.statisticTable {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4 mb-4 flex justify-center;
}

.viewDate {
  @apply self-end me-1;
}

.header {
  @apply flex flex-row justify-between items-center;
}

.btnGroup {
  @apply flex flex-row gap-3;
}

.tableWrap {
  @apply flex flex-col w-full h-full gap-4 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}
