.container {
  @apply w-full flex flex-col gap-y-4;
}

.bodyWrap {
  @apply h-full w-full flex flex-col justify-center rounded-lg;
}

.bodyTable {
  @apply flex-1 flex justify-center;
}

.paginationTable {
  @apply mt-4 mb-4 flex justify-center;
}
