.container {
  @apply flex-1 flex flex-col h-full w-full overflow-hidden gap-6;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.status {
  @apply flex gap-1 flex-wrap line-clamp-1;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.crmTitle {
  @apply flex items-center text-24-400-normal text-holly-950;
}

.crmRingCentralStatus {
  @apply ml-5 text-14-400-normal text-crimson-700 underline cursor-pointer;
  @apply hover:text-crimson-600;
  @apply transition-all duration-300;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.contentFilter {
  @apply flex flex-row items-center justify-between gap-2;
}

.statusLabel {
  @apply text-12-400-normal text-holly-950;
}
