#eventDailyTimeline {
  .eventContainer {
    @apply overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1 border-red-400 border;
  }

  .eventGroup {
    @apply relative;
    @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  }

  .collapseEventContainer {
    @apply px-[10px] border border-skeptic-100  rounded-xl;
  }

  .expandEventContainer {
    @apply flex flex-1 h-[95px] rounded-xl border-none justify-center;
    height: 60px !important;
  }
  // Scroll Style
  .eventContainer.rct-item::-webkit-scrollbar {
    @apply w-1;
  }

  .eventContainer.rct-item::-webkit-scrollbar-thumb {
    @apply bg-green-pea-700/30 rounded-full;
  }

  .eventContainer.rct-item::-webkit-scrollbar-track {
    @apply rounded-full bg-aqua-haze-50;
  }

  .expandEventDay {
    @apply flex flex-1 h-5 rounded-3xl p-0 pr-3 font-aeonik;
    height: 36px !important;
  }

  .assignees {
    @apply flex items-center justify-center ml-1;
  }

  .right {
    @apply flex flex-col justify-center truncate overflow-hidden;
  }

  .eventName {
    @apply w-auto h-auto text-14-400-24 text-white text-ellipsis whitespace-nowrap overflow-hidden;
  }

  .eventTime {
    @apply truncate mt-[2px] text-8-400-10 text-white overflow-hidden;
  }

  .expandWeek {
    @apply flex flex-1 flex-row gap-2 items-center flex-wrap overflow-y-auto;
  }

  .expandWeek .right {
    @apply flex-1;
  }

  .expandDay {
    @apply flex flex-1 items-center gap-2;
  }

  .avatar {
    width: 26px;
    height: 26px;
    background-color: #a38383;
    border-radius: 100%;
  }

  // Overwrite css of lib
  .eventContainer.rct-item {
    line-height: normal !important;
    background: unset !important;
    border: unset !important;
    color: unset !important;
    font-size: unset !important;
    padding: 0;
    padding-left: 6px;
    padding-right: 6px;
    z-index: 1 !important;
  }

  .tooltipWrap {
    @apply z-10 max-w-[170px];
  }

  .tooltip {
    @apply flex flex-col gap-1;
  }

  .tooltipText {
    @apply text-white flex-col flex;
    font-size: 12px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
  }
}

.popupContainer {
  @apply flex relative flex-col z-20 rounded-lg bg-white p-[30px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)];
  @apply transition duration-300 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
  width: 660px !important;
}

.eventWrap {
  @apply w-full text-left border-none outline-none h-[95px];
}
