.summaryTabComponent {
  @apply flex-1 bg-white justify-between font-aeonik overflow-hidden h-full flex flex-col;
}

.contentBody {
  @apply flex flex-col gap-[16px] pb-[20px] overflow-y-auto h-full pr-1;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.contentLabelValue {
  @apply flex flex-1 flex-row;
}

.contentLabel {
  @apply text-pewter-300 text-16-400-normal w-[100px] mr-[20px];
}

.contentValueDates {
  @apply flex-1 text-primary-teal-950 text-16-400-normal;
}

.contentValue {
  @apply overflow-hidden flex-1 text-ellipsis text-mineral-green-600 text-16-400-normal;
}

.contentValueNameTasks {
  @apply text-primary-teal-950 text-16-400-normal;
}

.label {
  @apply w-[4px] h-[4px] rounded-full bg-primary-teal-950 mr-1 inline-block mb-1;
}

.tasksContainer {
  @apply flex flex-1 flex-col gap-4;
}

.footerButton {
  @apply flex mt-[24px] justify-end;
}

.groupButton {
  @apply flex flex-row gap-4;
}

.line {
  @apply h-[1px] w-full bg-nebula-100 mt-6;
}

.contentStatus {
  @apply items-center;
}
