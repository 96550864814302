.caregiverScheduleTab {
  @apply overflow-hidden;
}

.eventContainer {
  @apply w-full h-full gap-[4px] pr-1 rounded-xl p-[6px] transition duration-300 ease-in-out;
  @apply relative;
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  @apply hover:opacity-80;
}

.eventContainer.monthEvent {
  @apply flex justify-center h-[60px] items-center;
}

.eventWrap {
  @apply flex flex-1 flex-row gap-2 items-center overflow-y-auto overflow-x-hidden;
}

.assignees {
  @apply flex items-center justify-center ml-1;
}

.firstLastName {
  @apply flex flex-col justify-center truncate overflow-hidden;
}

.eventName {
  @apply w-auto h-auto text-14-400-24 text-white text-ellipsis whitespace-nowrap overflow-hidden;
}

.eventTime {
  @apply truncate mt-[2px] text-8-400-10 text-white overflow-hidden;
}

.tooltipWrap {
  @apply z-10 max-w-[170px];
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white flex-col flex;
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.eventWrapUnavailable {
  @apply flex flex-col px-1 flex-1;
}

.eventUnavailable {
  @apply bg-primary-neutral-400 py-0 px-2;
}

.eventUnavailableTitle {
  @apply text-14-400-24 text-holly-950 text-ellipsis whitespace-nowrap overflow-hidden;
}

.eventUnavailableTime {
  @apply text-8-400-10 text-holly-950 text-ellipsis whitespace-nowrap overflow-hidden;
}

.eventModalTitle {
  @apply text-16-400-normal text-holly-950;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
