.originStyle {
  @apply w-full flex justify-center items-center px-3 py-[2px] rounded-[5px] bg-opacity-[20%] bg-primary-teal-800 text-14-400-normal font-aeonik;
}

.buttonStatusLabel {
  @apply pb-[3px];
}

.originStyle.teal800 {
  @apply text-white bg-primary-teal-800;
}

.originStyle.gray300 {
  @apply text-primary-teal-950 bg-primary-teal-800 bg-opacity-[20%];
}

// Custom Button Status Red 600 
.originStyle.red600 {
  @apply text-primary-red-600 bg-primary-red-600 bg-opacity-[10%];
}

// Custom Button Status Green Pea 700 
.originStyle.greenPea700 {
  @apply text-green-pea-700 bg-green-pea-700 bg-opacity-[10%];
}

// Custom Button Status Finn 900 
.originStyle.finn900 {
  @apply text-finn-900 bg-finn-900 bg-opacity-[10%];
}