.inputSearchWrapper {
  @apply relative;
}

.inputSearch {
  @apply w-full outline-none text-primary-teal-800 text-14-400-normal font-aeonik h-9 rounded-lg py-[5px] pl-[8px] pr-6 border border-primary-gray-200 truncate;
}

.inputSearch::placeholder {
  @apply text-primary-gray-500 font-aeonik;
}

.iconInputSearch {
  @apply absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer;
}

.inputSearch.service {
  @apply h-10 pr-8 border-nebula-100 border rounded-[1000px] py-[10px] pl-[11px] font-aeonik;
}

.inputSearch.service::placeholder {
  @apply text-primary-gray-500 font-aeonik;
}

.iconInputSearch.service {
  @apply absolute top-1/2 -translate-y-1/2 right-[10px] text-[20px];
}

.iconInputSearch.select {
  @apply absolute left-2 top-1/2 -translate-y-1/2 cursor-pointer w-4 h-4;
}

.inputSearch.select {
  @apply w-full outline-none text-primary-teal-800 text-14-400-normal font-aeonik h-9 rounded-lg py-[5px] pr-[8px] pl-8 border border-primary-gray-200;
}

.inputSearch.select::placeholder {
  @apply text-primary-pewter-300 font-aeonik;
}
