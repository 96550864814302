.currentTaskComponent {
  @apply h-[94px] w-full border rounded-lg border-nebula-100 flex items-center justify-between p-5;
}

.actionCurrentTask {
  @apply flex items-center gap-[10px];
}

.currentTaskGroup,
.durationGroup {
  @apply flex flex-col gap-[10px] w-[431px] h-full;
}

.title {
  @apply text-pewter-300 text-16-400-normal;
}

.taskName,
.durationTime {
  @apply text-mineral-green-600 text-20-400-normal line-clamp-1;
}
