.container {
  @apply relative w-full min-h-screen flex;
}

.logoSection {
  @apply relative shrink-0 flex items-center justify-center overflow-hidden;
  @apply basis-0 xl:basis-[700px];
  @apply transition-all ease-in-out duration-300;
}

.authBackgroundImage {
  @apply absolute top-0 left-0 w-full h-full bg-sign-in bg-[-300px_top] bg-no-repeat bg-cover mix-blend-multiply opacity-50;
}

.logo {
  @apply w-[334px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.formSection {
  @apply flex-1 w-full min-w-[320px] py-8 flex items-center justify-center overflow-hidden;
  @apply px-8 sm:px-[120px];
  @apply transition-all ease-in-out duration-300;
}

@media screen and (max-height: 680px) {
  .authBackgroundImage {
    @apply bg-left-top;
  }
}
