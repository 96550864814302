.itemHomeSafetyAssessmentComponent {
  @apply w-full flex flex-col gap-5;
}

.itemHomeSafetyAssessment {
  @apply p-5 border border-nebula-100 rounded-lg overflow-hidden;
  @apply grid grid-cols-6 gap-5;
}

.questionWrap {
  @apply flex flex-col col-span-5 gap-2;
}

.question {
  @apply text-16-400-normal text-green-pea-700 min-h-[37px];
}

.answersWrap {
  @apply flex flex-col col-span-1 gap-2;
}

.answers {
  @apply flex gap-2 items-center;
}

.actionYesNo {
  @apply border border-nebula-100 flex rounded-lg;

  &.reverse {
    @apply flex-row-reverse;
  }
}

.action {
  @apply flex items-center justify-center text-16-400-normal text-green-pea-700 min-w-[40px] transition-all duration-200;
}

.action.yes,
.action.no {
  @apply p-2;
}

.action.yes {
  @apply rounded-tl-lg rounded-bl-lg border-r border-nebula-100;

  &:hover {
    @apply bg-green-pea-700 text-white;
  }
}

.action.yes.lastItem {
  @apply rounded-tr-lg rounded-br-lg rounded-tl-none rounded-bl-none border-l border-nebula-100 border-r-0;

  &:hover {
    @apply bg-green-pea-700 text-white;
  }
}

.action.no {
  @apply rounded-tr-lg rounded-br-lg;

  &:hover {
    @apply bg-primary-red-400 text-white;
  }
}

.action.no.lastItem {
  @apply rounded-tl-lg rounded-bl-lg rounded-tr-none rounded-br-none;
}

.action.noAvailable {
  @apply rounded-lg p-2 border border-nebula-100;

  &:hover {
    @apply bg-primary-red-400 text-white;
  }
}

.action.yes.active {
  @apply bg-green-pea-700 text-white;
}

.action.no.active {
  @apply bg-primary-red-400 text-white;
}

.action.noAvailable.active {
  @apply bg-primary-red-400 text-white;
}

.buttonSubmit {
  @apply flex items-end h-full;
}

.notes {
  @apply text-12-400-normal text-green-pea-700/50;
}
