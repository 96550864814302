.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.label {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik mb-[10px];
}

.locationList {
  @apply absolute bg-white w-full border-[1px] z-50 px-2 pb-2 border-gray-300 mt-1 rounded-[8px];
}

.locationInput {
  @apply relative;
}

.itemLocation {
  @apply text-14-400-normal mt-2;
}

.googleMapContainer {
  @apply flex-1;
}

.startTime {
  @apply text-16-500-normal text-holly-950;
}
