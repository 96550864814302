.messagesList {
  @apply w-full h-full flex flex-col gap-5 overflow-hidden;
}

.messagesItems {
  @apply h-full overflow-y-auto mt-[2px] pr-2;
  &::-webkit-scrollbar {
    @apply w-1 mr-2;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.containerSpinner {
  @apply h-16;
}

.containerCenter {
  @apply flex justify-center items-center h-full flex-1;
}
