.invoicingBillingPolicyComponent {
  @apply flex flex-col items-center w-full;
}

.content {
  @apply w-full break-words flex flex-col gap-y-4 text-teal-800 text-16-500-normal border border-primary-geyser-100 rounded-lg p-6;
}

.invoicingBillingPolicyComponent::-webkit-scrollbar {
  @apply w-1;
}

.title {
  @apply text-holly-950 font-semibold text-16-400-normal;
}

.contentValue {
  @apply text-12-400-normal text-primary-pewter-300 font-aeonik;
}

.questionContent {
  @apply italic text-16-500-normal;
}

.groupItem {
  @apply flex flex-col gap-3;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[40px] ml-auto;
}
