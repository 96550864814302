.container {
  @apply w-full h-full flex flex-col gap-5;
}

.label {
  @apply block text-12-400-normal text-primary-pewter-300 capitalize font-aeonik mb-[10px];
}

.viewFallRiskRadio {
  @apply flex flex-row gap-5 items-center;
  @apply p-3 border border-primary-geyser-100 rounded-lg;
}

.viewRow {
  @apply grid grid-cols-2 items-center space-x-5;
}

.radioLabel {
  @apply text-12-400-normal;
}

.ambulationControl{
  @apply p-3 border border-primary-geyser-100 rounded-lg;
}
