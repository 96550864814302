.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.backTag {
  @apply flex flex-row w-fit cursor-pointer mb-3 select-none;
}

.textBack {
  @apply text-14-400-16 text-teal-800;
}

.header {
  @apply flex items-center justify-between gap-5 mb-6;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex-1 overflow-hidden;
}

.form {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.formBody {
  @apply flex-1 flex flex-col gap-5 pr-2 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.formActions {
  @apply flex items-center justify-end gap-3 mt-10;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}
