.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.headerToolBar {
  @apply flex mt-[14px] mb-[20px];
}

.invoiceIdLabel {
  @apply text-holly-950 font-aeonik text-24-400-normal;
}

.bodyWrap {
  @apply w-full flex flex-col gap-6 mb-5 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.threeCol {
  @apply grid grid-cols-3 gap-12;
}

.title {
  @apply text-holly-950 text-16-400-normal;
}

.bodyWrap {
  @apply w-full flex flex-col gap-2 flex-1 overflow-y-auto;
}

.groupInfo {
  @apply flex gap-3;
}

.textLabel {
  @apply text-primary-neutral-400;
}

.textValue {
  @apply text-primary-teal-950;
}

.content {
  @apply flex flex-col mt-4;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.rightContent {
  @apply mt-4 self-end;
}
