.container {
  @apply flex flex-col max-h-[100%] font-aeonik;
}

.contentModal {
  @apply overflow-y-auto flex-1 gap-5 flex flex-col pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.theeCol {
  @apply flex-1 gap-x-5 grid grid-cols-3;
}

.footerModal {
  @apply flex justify-end gap-3 pt-5;
}
