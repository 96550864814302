.container {
  @apply flex flex-col w-[414px] h-[146px] border rounded-lg border-nebula-100 p-[20px] gap-4 relative;
}

.containerShowAll {
  @apply h-[327px] pb-[11px];
}

.headButton {
  @apply flex flex-row justify-between;
}

.contentWrap {
  @apply flex flex-col gap-2;
}

.contentTitle {
  @apply text-primary-teal-950 text-16-400-normal truncate;
}

.contentDescription {
  @apply text-zinc-500 text-12-400-normal line-clamp-2;
}

.contentShowAll {
  @apply flex flex-1 flex-col gap-4;
}

.contentWrap {
  @apply flex flex-col gap-4;
}

.contentTime {
  @apply flex flex-row border border-nebula-100 gap-4 border-r-0 border-l-0 pb-4 pt-4 justify-between pr-[30px];
}

.timeWrap {
  @apply flex flex-row gap-[6px] justify-between items-center truncate;
}

.timeTitle {
  @apply text-gray-500 text-12-400-normal;
}

.timeValue {
  @apply text-gray-600 text-12-400-normal;
}

.contentNote {
  @apply flex flex-col gap-2 h-[60px];
}

.noteTitle {
  @apply text-gray-600 text-12-400-normal;
}

.noteDescriptions {
  @apply text-zinc-500 text-12-400-normal line-clamp-2;
}

.contentFooter {
  @apply flex flex-row justify-between bg-gray-100 absolute bottom-0 w-full left-0 p-[20px] pt-[11px] pb-[11px] rounded-b-[7px];
}

.imageCircleWrap {
  @apply flex flex-row gap-[10px];
}

.contentBirthDay {
  @apply flex flex-row gap-[6px] justify-center items-center;
}
