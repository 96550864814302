.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.headerToolBar {
  @apply mt-[14px] mb-[20px];
}

.body {
  @apply w-full h-full flex gap-6 pr-2 overflow-y-auto;
}

.body::-webkit-scrollbar {
  @apply w-1;
}

.infoDetailsWrap {
  @apply basis-1/2 mb-6 gap-5;
}

.mapSection {
  @apply basis-1/2 h-full;
}

.mapViewContainer {
  @apply h-[500px] rounded-[8px] overflow-auto border border-nebula-100 p-[10px];
}

.itemDetail {
  @apply py-3 grid grid-cols-2 border-b border-b-nebula-100;
}

.textLabel {
  @apply text-14-400-normal  text-mineral-green-600;
}

.valueContent {
  @apply text-14-400-normal text-mineral-green-600 flex flex-col gap-y-2 break-words;
}
