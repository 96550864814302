.myScheduleContainer {
  @apply h-full flex flex-col overflow-hidden;
}

.schedule {
  height: 100%;
  min-width: 1000px;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-y-auto flex flex-col gap-[4px] pr-1;
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

.weekEventWrap,
.dayEventWrap {
  @apply size-full;
}

.eventContainer {
  @apply size-full;
}

.eventName {
  @apply truncate text-12-400-normal text-holly-950 whitespace-normal line-clamp-1;
}

.eventTime {
  @apply truncate mt-[2px] text-gray-500 text-10-400-12;
}

.myScheduleWeekView {
  :global {
    .rbc-day-slot .rbc-event {
      background-color: unset;
      border: unset;
      min-height: 54px;
    }
  }
}

.myScheduleDayView {
  :global {
    .rbc-day-slot .rbc-event {
      background-color: unset;
      border: unset;
      min-height: 54px;
    }
  }
}

.bodyWrap {
  @apply h-full w-full flex flex-col overflow-hidden rounded-lg bg-white;
}

.scheduleWrap {
  @apply flex-1 overflow-y-auto h-full;
}
