.messageItem {
  @apply py-4 px-5 flex border-b-[1px] mt-[10px] border-nebula-100 relative items-start cursor-pointer;
}

.avatarFirstLastName {
  @apply flex items-center gap-3;
}

.messageItemContent {
  @apply flex flex-col gap-1 line-clamp-2 flex-1 mx-3;
}

.containerMessageContent {
  @apply flex flex-row gap-1;
}

.messageItemName {
  @apply text-14-400-normal text-holly-950 font-aeonik;
}

.messageItemMessage {
  @apply line-clamp-1 text-12-400-normal text-mineral-green-600;
}

.messageItemTime {
  @apply line-clamp-1 text-12-400-normal text-mineral-green-600 flex flex-col gap-1;
}

.messageIcon {
  @apply w-4 h-4;
}

.statusMessage {
  @apply flex justify-end;
}

.countUnSeen {
  @apply rounded-full text-white w-4 h-4 bg-azure-radiance-500 text-10-500-12 flex items-center justify-center;
}
.active {
  @apply bg-saltpan-50 rounded-[4px];
}