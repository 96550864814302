.container {
  @apply w-full h-full flex flex-col gap-4 overflow-hidden;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}
