.container {
  @apply w-full h-full overflow-hidden;
}

.body {
  @apply w-full max-h-full overflow-y-auto flex gap-5 flex-wrap;
}

.card {
  @apply w-[311px] min-w-[311px] rounded-lg border border-nebula-100 flex flex-col justify-between overflow-hidden;
}

.cardHeader {
  @apply px-[10px] pt-[10px] select-none overflow-hidden;
}

.cardHeaderLogo {
  @apply w-full h-[120px] rounded-lg flex items-center justify-center bg-apple-600/10 overflow-hidden;
}

.cardHeaderLogoImg {
  @apply w-[175px];
}

.cardBody {
  @apply flex-1 p-5;
}

.cardTitle {
  @apply text-18-400-22 text-holly-950 mb-3;
}

.cardDesc {
  @apply text-14-400-22 text-mineral-green-600;
}

.cardActions {
  @apply px-5 pb-5;
}

.cardActionsItem {
  @apply w-full h-9 flex items-center justify-center rounded-lg px-3 border border-gray-300 select-none outline-none cursor-pointer;
  @apply text-14-400-normal text-primary-teal-950;
  @apply hover:bg-gray-100 hover:border-gray-100;
  @apply transition-all duration-300;
}

.cardActionsItem.connecting {
  @apply pointer-events-none;
}

.cardActionsItem.disconnected {
  @apply text-primary-red-600 border-primary-red-600/50;
  @apply hover:bg-rose-50 hover:border-rose-50;
}
