.container {
  @apply relative w-full max-w-[500px];
}

.title {
  @apply text-36-500-46 text-holly-950 mb-[10px];
}

.desc {
  @apply text-16-400-26 text-mineral-green-600 mb-[30px];
}

.formVerifyCode {
  @apply flex flex-col gap-[10px] justify-center mb-[30px];
}

.verifyCodeWrap {
  @apply flex gap-2 justify-center;
}

.resendText {
  @apply text-mineral-green-600 mt-4 text-16-400-normal text-center;
}

.resendLink {
  @apply font-aeonik text-16-400-normal text-holly-950 ml-1;
}

.inputVerifyCode {
  @apply w-[75px] h-[48px] rounded-lg border border-green-pea-700/20 outline-none text-mineral-green-600;
  @apply text-center;
}

.errMessage {
  @apply text-12 px-1 text-primary-red-600;
}
