.container {
  @apply w-full flex flex-col gap-5;
}

.checkboxWrap {
  @apply flex items-center gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}
