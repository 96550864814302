.textEditor .ql-editor {
  height: auto !important;
  overflow-y: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

.filesGroup {
  @apply flex flex-col gap-2;
}
