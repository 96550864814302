.container {
    @apply relative w-full max-w-[500px] flex flex-col gap-[60px];
}

.imageEmoji {
    @apply w-[160px] h-[160px] m-auto;
}

.title {
    @apply text-36-500-46 text-holly-950 mb-[10px];
}

.desc {
    @apply text-16-400-26 text-mineral-green-600 mb-[30px];
}

.changePasswordSuccess {
    @apply  text-center flex flex-col;
}

.btnGroup {
    @apply m-auto;
}