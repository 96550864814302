.container {
    @apply relative w-full max-w-[500px];
}

.title {
    @apply text-36-500-46 text-holly-950 mb-[10px];
}

.desc {
    @apply text-16-400-26 text-mineral-green-600 mb-[30px];
}

.formResetPassword {
    @apply flex flex-col gap-5 mb-[30px];
}

.contentInput {
    @apply w-full relative mt-[10px];
}

.label {
    @apply text-12-400-16 text-primary-pewter-300;
}

.formControl {
    @apply text-primary-sirocco-500 text-12-400-16 w-full h-[36px] rounded-lg border-[1px] border-primary-geyser-100 outline-none py-[10px] px-[36px];
}

.formControl::placeholder {
    @apply text-12-400-16 text-primary-pewter-300 ;
}

.iconLeft,
.iconRight {
    @apply w-4 h-4;
}

.iconRight {
    @apply absolute top-1/2 right-[12px] -translate-y-1/2 cursor-pointer;
}

.iconLeft {
    @apply absolute top-1/2 left-[12px] -translate-y-1/2;
}

.messageError {
    @apply min-h-4 px-2 text-primary-red-600 text-12-400-normal left-0 top-full mt-[10px];
}