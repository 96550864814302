.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.headerToolBar {
  @apply mt-[14px] mb-[20px];
}

.bodyWrap {
  @apply w-full flex flex-col gap-6 mb-5 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.twoCol {
  @apply grid grid-cols-2 gap-10;
}

.callerInformationWrap,
.prospectInformation,
.referralSourceWrap,
.prospectCareNeeds,
.logThisCall {
  @apply flex flex-col gap-4;
}

.title {
  @apply text-holly-950 text-16-400-normal;
}

.body {
  @apply flex flex-col gap-3;
}
