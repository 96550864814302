.container {
  height: 100%;
  min-width: 1000px;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventContainer {
  @apply h-full w-full rounded-r-2xl relative border border-solid z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.eventContainerCollapse {
  @apply px-[10px];
}

.eventContainerExpand {
  @apply p-[6px] flex flex-col h-[95px];
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply bg-green-pea-700/30 rounded-full;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-aqua-haze-50;
}

.eventName {
  @apply truncate text-12-400-normal text-holly-950 whitespace-normal line-clamp-1;
}

.eventTime {
  @apply truncate mt-[2px] text-mineral-green-600 text-10-400-12;
}

.assigneeContainer {
  @apply mt-auto;
}

.tooltipWrap {
  @apply z-10 max-w-[170px];
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white flex-col overflow-hidden flex;
  font-size: 12px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}
