.menuItem {
  @apply flex justify-center items-center h-full;
}

.navLink {
  @apply w-full relative text-blue-smoke-400 last:mr-0 transition-all;
}

.navLink:hover {
  @apply bg-slate-100 text-green-pea-700;
}

.navLinkActive {
  @apply text-green-pea-700;
}

.navLinkActive::after {
  @apply content-[""] absolute top-full mt-1 left-0 w-full h-[1px] bg-green-pea-700;
}

.itemView {
  @apply flex gap-5 justify-between;
}

.btnSubMenu {
  @apply px-[10px] rounded-[6px] text-green-pea-700 py-[6px];

  &:hover {
    @apply bg-green-pea-700/10;
  }
}

.navLinkMenuActive {
  @apply text-green-pea-700 bg-green-pea-700/10;
}

.navLinkMenuActive::after {
  @apply content-[""] absolute top-full mt-1 left-0 w-full bg-transparent h-0;
}

.dropdownMenu {
  @apply bg-white fixed z-50 flex flex-col gap-1 p-4 rounded-[6px] border-gray-200 border-[1px];
}

.dropdownSubMenu {
  @apply ml-6;
}
