.overtimeSettingsComponent {
  @apply flex flex-col items-center w-full;
}

.content {
  @apply w-full break-words flex flex-col gap-y-4 text-teal-800 text-16-500-normal border border-primary-geyser-100 rounded-lg p-6;
}

.overtimeSettingsComponent::-webkit-scrollbar {
  @apply w-1;
}

.title {
  @apply text-holly-950 font-semibold text-16-400-normal;
}

.groupCol {
  @apply flex items-center gap-1;
}

.tableLink {
  @apply underline hover:underline hover:text-blue-smoke-500 cursor-pointer;
  @apply transition-all ease-in-out duration-300;
}

.questionContent {
  @apply italic text-16-500-normal;
  white-space: pre-line;
}

.value {
  @apply text-16-500-normal;
  white-space: pre-line;
}

.viewLink {
  @apply text-blue-smoke-500 ml-1;
}

.groupItem {
  @apply flex flex-col gap-3;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[40px] ml-auto;
}
