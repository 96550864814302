.container {
  @apply w-full rounded-full min-h-[48px] pl-[10px] pr-[6px] py-[6px] bg-aqua-haze-50 flex items-center justify-between overflow-hidden;
}

.inputMessageInput {
  @apply flex-1 w-full border-0 outline-none resize-none px-5 bg-transparent max-h-[80px];
  @apply text-14-400-normal text-mineral-green-600;
}

.inputMessageAction {
  @apply flex items-center border-r border-r-nebula-100;
}

.inputMessageActionItem {
  @apply w-6 h-6 object-cover mr-[10px] cursor-pointer;
}

.inputMessageSend {
  @apply w-[36px] h-[36px] rounded-full bg-primary-teal-800 flex items-center justify-center cursor-pointer overflow-hidden;
  @apply hover:bg-primary-teal-950;
  @apply transition-all ease-linear duration-75;
}

.inputMessageSendIcon {
  @apply w-6 h-6 object-cover;
}

.uploadInput {
  @apply hidden;
}
