.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-pewter-300 capitalize font-aeonik;
}

.selectBaseLabelRequired {
  @apply text-primary-red-600;
}

// Select
.btnSelect {
  @apply min-w-[140px] flex justify-between items-center gap-x-1 p-[10px] bg-white border border-primary-geyser-100 rounded-lg;
}

.btnPlaceholder {
  @apply text-12-400-normal truncate text-primary-sirocco-500 capitalize font-aeonik;
}

.btnText {
  @apply text-12-400-normal text-primary-sirocco-500 truncate;
}

.iconActive {
  @apply rotate-180;
}

// Option
.optionList {
  @apply z-[1000] flex flex-col rounded-lg border max-h-[300px] border-primary-geyser-100 bg-white px-4 py-4 w-[var(--button-width)];
  max-height: 400px !important;
  @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)] overflow-hidden;
  overflow: hidden !important;
}

.optionItem {
  @apply w-full p-[8px] text-12-400-normal text-primary-sirocco-500 cursor-pointer rounded-lg mt-2 flex items-center;
}

.options {
  @apply h-full overflow-y-auto pr-1;
  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.optionActive {
  @apply bg-primary-teal-800/10 text-primary-teal-800;
}

.optionHover {
  @apply bg-primary-teal-800/10 text-primary-teal-800;
}

// No data
.optionNoData {
  @apply w-full p-[8px] text-12-400-normal text-primary-sirocco-500 rounded-lg mt-[2px];
}

// Err Message
.errMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.bgDisable {
  @apply bg-aqua-squeeze-100;
}

.colorStatus {
  @apply w-2 h-2 bg-black rounded-xl mr-2;
}

.subLabel {
  @apply text-12-400-16 text-gray-500 ml-2 mb-2;
}
