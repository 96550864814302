.container {
  @apply flex flex-col;
}

.fieldControl {
  @apply w-full flex justify-between items-center text-shadow-green-950 text-12-400-normal px-2 rounded-lg border border-primary-gray-300;
}

.valueField {
  @apply flex-1 text-start;
}

.placeholderText {
  @apply text-primary-sirocco-500 text-12-400-normal;
}

.icon {
  @apply w-[15px] h-[15px];
}

.errMessage {
  @apply min-h-4 px-2 text-primary-red-600 text-12-400-normal left-0 top-full mt-[10px];
}

.bgDisable {
  @apply bg-aqua-squeeze-100;
}
