.container {
  @apply w-full;
}

.label {
  @apply flex text-12-400-normal text-primary-pewter-300 truncate capitalize mb-[10px];
}

.uploadContainer {
  @apply size-[100px] px-[10px] cursor-pointer flex items-center justify-center bg-primary-teal-800/5 overflow-hidden;
  @apply border border-dashed border-primary-teal-800 rounded-md;
  @apply hover:bg-primary-teal-800/10;
}

.uploadInput {
  @apply hidden;
}

.uploadContentGroup {
  @apply flex items-center justify-center;
}

.uploadIcon {
  @apply w-[14px] h-[14px] object-cover;
}

.uploadContent {
  @apply text-12-400-normal text-primary-teal-800 truncate ml-[6px];
}

.fileImg {
  @apply size-full rounded-md object-cover;
}

.fileIcon {
  @apply size-full p-2 bg-primary-gray-300 rounded-md object-cover;
}

.filesUpload {
  @apply flex flex-wrap w-fit gap-2;
}

.file {
  @apply size-[100px] relative p-2 overflow-hidden bg-primary-gray-300/40 border border-primary-gray-300/40 rounded-md;
}

.file.error {
  @apply bg-primary-red-600/5 border border-dashed border-primary-red-600/50;
}

.fileContent {
  @apply w-full h-full overflow-hidden;
}

.fileClose {
  @apply absolute top-1 right-1;
  @apply size-5 p-[6px] bg-primary-gray-200 rounded-full cursor-pointer;
  @apply hover:bg-primary-gray-300;
  @apply transition-all duration-300;
}

.fileCloseImg {
  @apply w-full h-full object-cover;
}

.errorMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.required {
  @apply text-primary-red-600;
}
