@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.container {
  @apply fixed top-0 right-0 bottom-0 left-0 font-aeonik z-30 items-center justify-end flex;
}

.containerModal {
  @apply w-[450px] h-full bg-white p-[30px] relative flex overflow-hidden shadow-[2px_26px_54px_26px_rgba(33,34,39,0.1)];
  animation: slideIn 0.3s forwards;

  &.closing {
    animation: slideOut 0.3s forwards;
  }
}

.iconCloseModal {
  @apply absolute top-[10px] right-[10px] cursor-pointer;
}

.bodyModal {
  @apply flex flex-1 flex-col overflow-auto;
}
