.container {
  @apply flex flex-col text-14-400-normal font-aeonik relative select-none;
}

.checkbox {
  @apply outline-none;
}

.checkboxSingleWrap {
  @apply flex items-start gap-[6px] w-fit;
}

.checkboxSingle {
  @apply border-primary-neutral-400 cursor-pointer size-4 border rounded-sm flex items-center justify-center transition duration-200 ease-in-out;
}

.checkboxSingle.checked {
  @apply bg-green-pea-700 border-transparent;
}

.checkboxSingle.disabled {
  @apply opacity-50;
}

.label {
  @apply w-fit text-primary-sirocco-500 cursor-pointer;
}

.messageError {
  @apply mt-1 text-10 px-1 text-primary-red-600;
}
