.infoTabComponent {
  @apply w-full h-full overflow-y-auto;
}

.body {
  @apply w-full h-full flex gap-6 mb-5;
}

.infoDetailsWrap {
  @apply basis-1/2 mb-6 flex flex-col gap-3;
}

.mapSection {
  @apply basis-1/2 h-full;
}

.viewAddress {
  @apply pb-3 flex gap-2;
}

.mapViewContainer {
  @apply h-[500px] rounded-[8px] overflow-auto border border-nebula-100 p-[10px];
}

.infoTabComponent::-webkit-scrollbar {
  @apply w-1;
}

.textLabel {
  @apply text-14-400-normal text-primary-neutral-400;
}

.valueContent {
  @apply text-14-400-normal text-primary-teal-950 flex flex-col gap-y-2 break-words;
}
