.container {
    @apply flex flex-col;
}
  
// Label
.label {
    @apply mb-[10px] text-12-400-normal text-primary-pewter-300 capitalize;
}
  
.uploadFileContainer {
    @apply w-full flex items-center flex-wrap gap-[10px];
}

.uploadFile {
    @apply flex gap-[10px] flex-wrap;
}

.imgFile {
    @apply w-[35px] h-[35px];
}
  
// Img Preview
.filePreviewWrap {
    @apply flex items-center gap-2 relative border p-[10px] rounded-[5px] h-[55px] w-[180px];
}

.files {
    @apply flex items-center gap-[10px];
}

.file {
    @apply flex flex-col gap-1;
}
  
.imgPreview {
    @apply w-full h-full rounded-lg object-cover;
}
  
.iconClose {
    @apply cursor-pointer rounded-full absolute top-1 right-1; 
}
  
.iconCloseImg {
    @apply size-[8px];
}

.fileName {
    @apply text-16-400-normal text-primary-teal-950 line-clamp-1;
}

.typeFile {
    @apply text-10-400-12 text-pumice-300 cursor-pointer;
    
    &:hover {
        @apply text-primary-pewter-300
    }
}

.browseBtn {
    @apply text-12-700-14 text-green-pea-700 underline;
}
  
// Btn Upload
.btnUpload {
    @apply w-[55px] h-[55px] flex items-center justify-center;
    @apply rounded-[5px] bg-white p-[10px] hover:opacity-60;
    @apply text-primary-teal-800 text-12-400-normal truncate;
    @apply border border-primary-geyser-100 cursor-pointer;
}
  
.inputUpload {
    @apply hidden;
}
  
// Err Message
.errMessage {
    @apply mt-1 text-10 px-1 text-primary-red-600;
}