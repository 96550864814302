.container {
  @apply flex flex-col max-h-[100%] font-aeonik;
}

.contentModal {
  @apply overflow-y-auto flex flex-col gap-7;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.radioGroup {
  @apply flex flex-row gap-10 w-full mt-[10px];
}

.textLabel {
  @apply flex flex-row gap-[2px] mb-[10px] w-full items-center text-primary-pewter-300 text-12-400-normal;
}

.footerModal {
  @apply flex justify-end gap-3 pt-5;
}

.labelRequired {
  @apply text-primary-red-600;
}

.inputBaseLabelRequired {
  @apply text-primary-red-600;
}
