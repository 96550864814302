.container {
  @apply relative;
}

.viewButtonOptions {
  @apply h-[92px] w-[123px] absolute right-0 top-5 z-10 bg-white border border-gray-300/30 p-[10px] rounded-[8px] space-y-2 shadow-[6px_26px_54px_0_rgba(9,9,9,0.15)];
}

.buttonOption {
  @apply flex gap-[6px] justify-start items-center w-full h-[33px] py-[8px] px-[6px] rounded-[6px];

  &:hover {
    @apply bg-rose-50;
  }
}

.labelEdit {
  @apply text-primary-teal-950 text-14-400-normal;
}

.labelDelete {
  @apply text-primary-red-600 text-14-400-normal;
}
