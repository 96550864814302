.conversationContainer {
  @apply w-full h-full flex flex-col relative overflow-hidden;
}

.conversationHeader {
  @apply flex items-start justify-between w-full pb-2 border-b border-nebula-100;
}

.conversationContent {
  @apply overflow-y-scroll flex flex-col-reverse relative flex-1;
}

.dateGroup {
  @apply flex w-full justify-center items-center h-10;
}

.textDateGroup {
  @apply px-6 text-12-400-normal text-green-pea-700/40 flex justify-center items-center;
}

.conversationInputMessage {
  @apply w-full relative mt-3 flex justify-end flex-col;
}

.containerCenter {
  @apply flex justify-center items-center h-full flex-1;
}

.invisibleSpinner {
  @apply invisible;
}