.container {
  @apply w-full h-full flex flex-col gap-y-[30px] overflow-hidden;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-[20px] overflow-y-auto bg-white rounded-[8px] px-[30px] pt-10 pb-[30px];
}

.headGroup {
  @apply w-full flex justify-between border-b border-b-nebula-100;
}

.basicInfoGroup {
  @apply flex max-h-[91px] mb-[30px];
}

.basicInfo {
  @apply ml-[10px] flex flex-col w-[370px] gap-3;
}

.nameGroup,
.statusGroup {
  @apply flex gap-1 text-16-400-normal text-pewter-300 items-center;
}

.fullName {
  @apply text-24-400-normal text-holly-950;
}

.navTabGroup {
  @apply self-end;
}

.tabContent {
  @apply flex flex-col h-full overflow-auto;
}

.tabContent::-webkit-scrollbar {
  @apply hidden;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
