.conversationUserInfo {
  @apply w-full flex flex-row gap-3 items-start;
}

.conversationUserContent {
  @apply flex flex-col gap-1;
}

.conversationUserName {
  @apply text-holly-950 text-14-400-normal;
}

.conversationUserStatus {
  @apply flex items-center gap-1;
}

.conversationDot {
  @apply w-[6px] h-[6px] rounded-full bg-malachite-600;
}

.conversationStatus {
  @apply text-12-400-normal text-mineral-green-600;
}

.conversationUserTyping {
  @apply text-mineral-green-600 text-12-400-normal h-[14px];
}
