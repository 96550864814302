.ql-toolbar {
  @apply flex flex-row justify-between bg-white;
}

.ql-toolbar.ql-snow {
  @apply h-[40px] flex items-center rounded-t-lg;
  border: 1px solid #d9e1df !important;
  border-bottom: 1px solid #d9e1df !important;
}

.ql-container.ql-snow {
  @apply flex flex-1 overflow-hidden rounded-b-lg bg-white;
  border: 1px solid #d9e1df !important;
}

.ql-snow .ql-stroke {
  stroke: rgba(36, 96, 77, 0.7) !important;
}

.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover {
  color: #24604d !important;
}

.ql-toolbar button:hover .ql-fill,
.ql-toolbar button:focus .ql-fill,
.ql-toolbar button.ql-active .ql-fill,
.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #24604d !important;
}

.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button:focus .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke,
.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-toolbar button:hover .ql-stroke-miter,
.ql-toolbar button:focus .ql-stroke-miter,
.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #24604d !important;
}

.ql-snow .ql-fill {
  fill: rgba(36, 96, 77, 0.7) !important;
}

.ql-snow .ql-picker-label {
  color: rgba(36, 96, 77, 0.7) !important;
}

.ql-editor.ql-blank:before {
  color: #5c747d !important;
  font: normal 400 12px/1 sans-serif !important;
}

.ql-editor {
  @apply w-full text-12-400-normal text-primary-sirocco-500 m-[15px];
  padding: 0 !important;
}

.ql-editor {
  height: 150px !important;
  overflow-y: auto;
}

.ql-editor::-webkit-scrollbar {
  @apply w-1;
}

.ql-editor::-webkit-scrollbar-thumb {
  @apply rounded-full bg-mineral-green-600 cursor-pointer;
}

.ql-editor::-webkit-scrollbar-track {
  @apply rounded-full bg-alto-200;
}

.ql-bubble .ql-editor a {
  @apply text-blue-500;
}
