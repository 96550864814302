.container {
  @apply w-full h-full flex flex-col gap-y-6 bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.headGroup {
  @apply w-full border-b mb-4 border-b-nebula-100;
}

.navTabGroup {
  @apply self-end;
}

.tabContent {
  @apply h-full overflow-hidden;
}

.buttonItem {
  @apply py-2 px-1.5 text-gray-500 text-12-400-16;
}

.buttonActive {
  @apply py-2 px-1.5 bg-primary-teal-800/10 rounded-[8px] text-primary-teal-800 text-12-400-16;
}

.buttonItem:hover,
.buttonActive:hover {
  @apply text-primary-teal-800 rounded-[6px] cursor-pointer;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
