.container {
  @apply w-full h-full bg-white p-5 rounded flex flex-col gap-6 overflow-hidden;
}

.title {
  @apply text-24-400-normal text-holly-950;
}

.body {
  @apply flex-1 overflow-y-auto pr-1;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.ringCentralBox {
  @apply p-5 w-1/3 rounded-lg bg-white border border-primary-gray-200 flex flex-col items-center justify-center;
}

.ringCentralTitle {
  @apply text-16-500-normal text-holly-950 mb-4;
}

.ringCentralStatus {
  @apply text-24-600-normal text-holly-950 capitalize;
}

.ringCentralStatus.connecting {
  @apply text-blue-smoke-500;
}

.ringCentralStatus.connected {
  @apply text-green-pea-600;
}

.ringCentralConnect {
  @apply mt-6 flex items-center justify-center gap-2 cursor-pointer h-9 px-3 rounded-lg bg-green-pea-700 outline-none select-none;
  @apply text-white text-14-400-normal;
  @apply hover:bg-green-pea-600;
  @apply transition-all duration-300;
  @apply disabled:bg-primary-black-100 disabled:text-primary-black-400;
}

.ringCentralConnect.disconnected {
  @apply bg-crimson-700;
  @apply hover:bg-crimson-600;
}

.btnIcon {
  @apply w-4 h-4 object-cover;
}
