.container {
  @apply w-full h-full bg-white p-5;
}

.content {
  @apply w-full h-full py-[30px];
}

.eventContainer {
  @apply w-full h-full gap-[4px] border border-l-[4px] pr-1 rounded-lg p-[12px] bg-transparent;
  @apply relative backdrop-blur-3xl;
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
}

.eventContainer.monthEvent {
  @apply h-fit max-h-[75px] overflow-hidden flex flex-col;
}

.eventWrap {
  @apply flex flex-col overflow-hidden;
}

.eventName {
  @apply text-12-400-normal text-green-pea-700 line-clamp-3 whitespace-normal;
}

.tooltipWrap {
  @apply z-10 max-w-[370px] text-12-400-normal text-white;
}
